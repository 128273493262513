import {
    Alert,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Typography
  } from "antd";
  import { useTranslation } from "react-i18next";
  
  import { Link, useNavigate } from "react-router-dom";
  import ContentHeader from "../../components/ContentHeader";
  import { CSSProperties, FocusEvent, useEffect, useState } from "react";
  import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
  import dayjs from "dayjs";
  import { getCleASS,  nssNormalize, phoneNormalize } from "../../helpers/validators";
import { getAllWilayas, getCommunes } from "../../api/wilayas";
import { ICommune, IWilaya } from "../../types/types";
import CustomInputNumber from "../../components/CustomInputNumber";
import { checkNewEmail } from "../../api/demande";
import { ChangeEmailService } from "../../api/auth";
import { COLOR_PRIMARY2 } from "../../config/constants";

const styles: { [key: string]: CSSProperties | undefined } = { 

  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",
  },
  heroWelcome: {
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },

}

  const ChangeEmailPage = () => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [wilayas, setWilayas] = useState<IWilaya[]>([]);
    const [communes, setCommunes] = useState<ICommune[]>([]);
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
  useEffect(()=>{
    getAllWilayas().then(res=>{
        setWilayas(res);
    }).catch(err=>{
        setAlert(true);
        setErrors(err.message);
    });
  },[])
    const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
      let date = e.target.value;
      if (date) {
        let date1 = dayjs(date, "YYYY-MM-DD");
        let date2 = dayjs(date, "DD-MM-YYYY");
        let date3 = dayjs(date, "DD/MM/YYYY");
        let date4 = dayjs(date, "YYYY/MM/DD");
        let res = date1.isValid()
          ? date1
          : date2.isValid()
          ? date2
          : date3.isValid()
          ? date3
          : date4.isValid()
          ? date4
          : null;
        if (res) {
          form.setFieldsValue({
            demandeur: {
              dateNaissance: res,
            },
          });
        }
      }
      form.validateFields([["demandeur", "dateNaissance"]]);
      form.validateFields([["demandeur", "numSecuriteSociale"]]);
    };
  
    const nssPattern = () => {
      let nss = form.getFieldValue(["demandeur", "numSecuriteSociale"]) || "";
      let dateNaissance =
        form.getFieldValue(["demandeur", "dateNaissance"]) || "";
      if (`${nss}`.length === 12 && dateNaissance) {
        let year = dateNaissance.format("YY");
        let key = getCleASS(nss);
        let nss2 = `${nss}`.slice(2, 10);
        let pattern = `^${year}${nss2}${key}$`;
        return new RegExp(pattern);
      }
      return new RegExp(`^[0-9]{12}$`);
    };
    const onWilayaChange = (value: number) => {
        form.setFieldsValue({ demandeur: { communeNaissanceId: undefined } });
        return getCommunes(value).then((res) => setCommunes(res));
      };
    
    const onFinish = (values:any) => {

        console.log(values.demandeur);
       setLoading(true);
      if (executeRecaptcha) {
        executeRecaptcha().then((captchaToken) => {
          ChangeEmailService({...values.demandeur,captchaToken})
            .then((res) => {
              if (res.success) {
                navigate("/login", { replace: true });
                Modal.success({
                  title: `${t("formulaire_envoi_confirmation_compte")}`,
                  content: `${t("envoi_confirmation_account_success")}`,
                  direction: i18n.dir(),
                });
              } else {
                setAlert(true);
                setErrors(res.error);
              }
            })
            .finally(() => setLoading(false));
        });
      } else {
        Modal.error({
          title: t("error"),
          content: (
            <ul>
              <li>{t("captcha_error")}</li>
            </ul>
          ),
        });
      }
    };
  
    const colSpan = {
      xs: 24,
       md: 20,
       lg: 15,
       xl: 12,
      xxl: 10,
    };
  
    return (
      <>
        <ContentHeader title={t("formulaire_change_email")} />
        
       
        <Row justify="center" style={{ flex: 1,padding:20 }}>
        <Col
        lg={16}
        md={16}
        xs={20}
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: 10,
          margin:40,
          border: "1px solid red",
        }}
      >
        <h5
          style={{
            ...styles.heroWelcome,
            textAlign: "center",
            padding: 10,
            color: "red",
          }}
        >
          {t("important")}
        </h5>
        <p style={{ textAlign: "center", lineHeight: 1.6, fontWeight: "bold" }}>
        {/* <Typography.Text> */}
        {t("messages.change_email_important")}
        {/* </Typography.Text> */}
        </p>
      </Col>
          <Col
            {...colSpan}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {alert && (
              <Alert
                message={t("error")}
                description={
                  <ul>
                    {errors.map((err: string, index: number) => {
                      return <li key={index}>{t(err)}</li>;
                    })}
                  </ul>
                }
                type="error"
                showIcon
                style={{ margin: "20px 0" }}
                closable
                afterClose={() => setAlert(false)}
              />
            )}
            <Form
              onFinish={onFinish}
              labelCol={{ span: 10 }}
              labelAlign="left"
              form={form}
              validateTrigger="onBlur"
              scrollToFirstError={{
                behavior: "smooth",
              }}
              validateMessages={{
                required: t("messages.champ_obligatoire").toString(),
              }}
            >
              <div
                style={{
                  border: "2px solid #ddd",
                  padding: 10,
                  marginBottom: 20,
                }}
              >
                <p
                  style={{
                    width: i18n.language === "ar" ? "90px" : "140px",
                    marginTop: "-24px",
                    marginLeft: "5px",
                    background: "white",
                  }}
                >
                  {t("inscription.date_naissance")}
                </p>
                <Form.Item
                  name={["demandeur", "presumeDemandeur"]}
                  label={t("inscription.presume")}
                  initialValue={false}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value={false}>{t("inscription.non")}</Radio>
                    <Radio value={true}>{t("inscription.oui")}</Radio>
                  </Radio.Group>
                </Form.Item>
  
                <Form.Item
                  name={["demandeur", "dateNaissance"]}
                  label={t("inscription.date_naissance")}
                  rules={[
                    { required: true },
                    {
                      validator(_, value) {
                        const presume = form.getFieldValue([
                          "demandeur",
                          "presumeDemandeur",
                        ]);
  
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (presume) {
                          if (value.month() !== 0 || value.date() !== 1) {
                            return Promise.reject(
                              new Error(t("messages.presume_invalide").toString())
                            );
                          }
                        }
                        if (value.isAfter(dayjs().subtract(16, "year"))) {
                          return Promise.reject(
                            new Error(t("messages.date_cnl_invalide").toString())
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    onBlur={onBlurDateNaissance}
                    style={{ width: "100%" }}
                    placeholder=""
                    defaultPickerValue={dayjs().subtract(16, "year")}
                    disabledDate={(date) =>
                      date.isAfter(dayjs().subtract(16, "year"))
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={["demandeur", "numSecuriteSociale"]}
                label={t("inscription.nss")}
                normalize={nssNormalize}
                dependencies={[["demandeur", "dateNaissance"]]}
                rules={[
                  { required: true },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      let test = nssPattern().test(value);
                      if (!test) {
                        return Promise.reject(
                          new Error(t("messages.nss_invalide").toString())
                        );
                      }
  
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
        name={["demandeur", "wilayaNaissanceId"]}
        label={t("inscription.wilaya_naissance")}
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "communeNaissanceId"]}
        label={t("inscription.commune_naissance")}
        dependencies={["wilayaNaissanceId"]}
        rules={[{ required: true }]}
      >
        <Select>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "numTel"]}
        normalize={phoneNormalize}
        label={t("inscription.n_tel")}
        rules={[
          { required: true },
          { max: 10, message: t("messages.tel_invalide").toString() },
          {
            pattern: /^(0)(5|6|7)[0-9]{8}$/,
            message: t("messages.tel_invalide").toString(),
          },
        ]}
      >
        <CustomInputNumber />
      </Form.Item>
      <Form.Item
        name={["demandeur", "email"]}
        label={t("nouveau_email")}
        rules={[
          { required: true },
          { type: "email", message: t("messages.email_invalide").toString() },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              return (
                executeRecaptcha &&
                executeRecaptcha().then((token) =>
                  checkNewEmail(value, token).then((success) => {
                    if (!success) {
                      return Promise.reject(
                        new Error(t("messages.email_exist").toString())
                      );
                    }
                    return Promise.resolve();
                  })
                )
              );
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "confirmEmail"]}
        label={t("confirm_nouveau_email")}
        dependencies={["demandeur", "email"]}
        rules={[
          { required: true },
          { type: "email", message: t("messages.email_invalide").toString() },
          ({ getFieldValue }) => ({
            validator(_, value) {
              
              if (!value || getFieldValue(['demandeur','email']) === value) {
              
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("messages.new_email_not_match") || ''));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button loading={loading} type="primary" htmlType="submit">
                  {t("envoyer")}
                </Button>
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/login">
                  <Button
                    style={{
                      padding: 0,
                    }}
                    type="link"
                  >
                    {t("login.login")}
                  </Button>
                </Link>
                <Link to="/inscription">
                  <Button
                    style={{
                      padding: 0,
                    }}
                    type="link"
                  >
                    {t("login.non_inscrit")}
                  </Button>
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </>
    );
  };
  
  export default ChangeEmailPage;
  