import { CSSProperties } from "react";
import { COLOR_PRIMARY2 } from "../../config/constants";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    minHeight: "calc(55vh)",
    backgroundColor: "white",
    color: "black",
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: 20,
    gap: 30,
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",

    lineHeight: 2,
  },
  heroWelcome: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: COLOR_PRIMARY2,
  },
  btnSinscrire: {
    borderRadius: "50px",
    backgroundColor: COLOR_PRIMARY2,
    color: "white",
    paddingInline: 30,
    marginLeft: 50,
  },
};
const RefReferencesSection = () => {
  const { t } = useTranslation();

  return (
    <Row
      style={styles.rowContainer}
      justify="center"
      id="references_reglementaires"
    >
      <Col style={styles.colContainer} xs={22} lg={20}>
        <Space direction="vertical" size="small">
          <h5 style={styles.heroWelcome}>
            {t("references_reglementaires.title")}
          </h5>
          <ul>
            <li>
              <p>{t("references_reglementaires.r1")}</p>
            </li>
            <li>
              <p>{t("references_reglementaires.r2")}</p>
            </li>
            <li style={{ lineHeight: 1.6 }}>
              <p>{t("references_reglementaires.r3")}</p>
            </li>
            <li style={{ lineHeight: 1.6 }}>
              <p>{t("references_reglementaires.r4")}</p>
            </li>
            <li style={{ lineHeight: 1.6 }}>
              <p>{t("references_reglementaires.r5")}</p>
            </li>
          </ul>
        </Space>
      </Col>
      <Col
        className={"inscription-pic"}
        style={{ ...styles.secondColContainer }}
      ></Col>
    </Row>
  );
};

export default RefReferencesSection;
