import { MenuProps } from "antd";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { COLOR_PRIMARY2 } from "../../config/constants";
const mobileStyles = {
  btn: {
    border: "none",
    color: COLOR_PRIMARY2,
    boxShadow: "none",
    fontWeight: "bold",
    justifyContent: "center",
    margin: 0,
    cursor: "pointer",
  },
};

const desktopStyles = {
  btn: {
    border: "none",
    color: COLOR_PRIMARY2,
    boxShadow: "none",
    fontWeight: "bold",
    fontSize: 16,
    margin: 0,
    cursor: "pointer",
  },
};

export const getNavItems = (
  type: "desktop" | "mobile",
  setOpen: () => void,
  t: TFunction
) => {
  const styles = type === "desktop" ? desktopStyles : mobileStyles;

  const res: MenuProps["items"] = [
    {
      label: (
        <Link to="/#a_propos_du_service" style={styles.btn} onClick={setOpen}>
          {t("a_propos_du_service_menu")}
        </Link>
      ),
      key: "a_propos_du_service",
    },
    {
      label: (
        <Link
          to="/#conditions_eligibilite"
          style={styles.btn}
          onClick={setOpen}
        >
          {t("conditions_eligibilite.title")}
        </Link>
      ),
      key: "conditions_eligibilite",
    },
    {
      label: (
        <Link
          to="/#montant_aide_financiere"
          style={styles.btn}
          onClick={setOpen}
        >
          {t("montant_aide_financiere")}
        </Link>
      ),
      key: "montant_aide_financiere",
    },
    {
      label: (
        <Link
          to="/#references_reglementaires"
          style={styles.btn}
          onClick={setOpen}
        >
          {t("references_reglementaires.title")}
        </Link>
      ),
      key: "references_reglementaires",
    },
    {
      label: (
        <Link to="/#demarche_suivie" style={styles.btn} onClick={setOpen}>
          {t("demarche_suivie")}
        </Link>
      ),
      key: "4",
    },
  ];
  return res;
};
