import { IChangeEmail, ILoginCredentials, IResetPassword } from "../types/types";
import { API } from "./instance";
import { getDataFromResponse, getErrorsFromResponse } from "./utils";

export const authLogin = (credentials: ILoginCredentials) =>
  API.post<string>("users/authenticate", { ...credentials })
    .then(({ data }) => getDataFromResponse(data))
    .catch((ex) => getErrorsFromResponse(ex));

export const confirmEmail = (data: { email: string; token: string }) =>
  API.put<boolean>(`/users/confirmemail`, data)
    .then(({ data }) => data)
    .catch((ex) => ex);
    export const confirmChangeEmail = (data: { email: string; token: string }) =>
    API.put<boolean>(`/users/confirmchangeemail`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((ex) => ex);
  
export const authResetPassword = (email: string) =>
  API.get<boolean>(`/users/getresetpasswordlink?Email=${email}`)
    .then(({ data }) => getDataFromResponse(data))
    .catch((ex) => getErrorsFromResponse(ex));

export const authNewPassword = (body: IResetPassword) =>
  API.put<boolean>(`/users/resetpassword`, body)
    .then(({ data }) => getDataFromResponse(data))
    .catch((ex) => getErrorsFromResponse(ex));
    export const ResendConfirmationLink = ({
      nss,
      captchaToken,
    }: {
      nss: string;
      captchaToken: string;
    }) =>
      API.post<boolean>(`/users/resendemailconfirmationlink`, { nss, captchaToken })
        .then(({ data }) => getDataFromResponse(data))
        .catch((ex) => getErrorsFromResponse(ex));
export const ChangeEmailService = (data:IChangeEmail) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const body = {...data,dateNaissance:data.dateNaissance.format(DATE_FORMAT)}
  return API.post<boolean>(`/users/changeemail`,body)
    .then(({ data }) => getDataFromResponse(data))
    .catch((ex) => getErrorsFromResponse(ex));
  }
        