import { ISession } from "../types/session";
import { API } from "./instance";
import { getDataFromResponse, getErrorsFromResponse } from "./utils";

const getSessionInfo = () => {
  return API.get<ISession>("sessions/getinfos")
    .then((data) => getDataFromResponse(data))
    .catch((err) => getErrorsFromResponse(err));
};

export { getSessionInfo };
