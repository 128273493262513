import { ConfigProviderProps } from "antd/lib/config-provider";
import { ThemeConfig } from "antd/es/config-provider/context";
import { COLOR_SECONDARY, COLOR_PRIMARY2 } from "./constants";

const appTheme: ThemeConfig = {
  token: {
    colorBgLayout: "#FFF",
    colorPrimary: COLOR_PRIMARY2,

    fontFamily: "Noto Kufi Arabic,Maven Pro, sans-serif",
    fontSize: 16,
    colorLink: COLOR_PRIMARY2,

    // colorBgContainer: '#232A34',
    // colorBgLayout,
    // borderRadius: 0
  },
  components: {
    Input: {
      borderRadius: 0,
    },
    Steps: {
      controlHeight: 48,
    },
  },
};

export const defaultAppConfig: ConfigProviderProps = {
  theme: appTheme,
  direction: "rtl",
};
