import { Button, Modal, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { RcFile } from "antd/es/upload";

const SUPPORTED_FORMATS = ["application/pdf", "image/jpg,image/jpeg"];
// ,
export default function FileUpload({
  action,
  onUploadFinish,

  headers,
}: {
  action: any;
  onUploadFinish: any;
  headers: object;
}) {
  const { t } = useTranslation();
  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 <= 1;
    if (!isLt2M) {
      Modal.error({
        title: t("error"),
        content: (
          <ul>
            <li>{t("taille_fichier")}</li>
          </ul>
        ),
      });
    } else {
      console.log(file);
      if (file.type !== "image/jpeg" && file.type !== "application/pdf") {
        Modal.error({
          title: t("error"),
          content: t("type_fichier"),
        });
      }
    }
    return isLt2M;
  };
  return (
    <>
      <Upload
        headers={headers as any}
        action={action}
        method={"PUT"}
        name={"Document"}
        listType="text"
        onRemove={() => onUploadFinish(undefined)}
        beforeUpload={beforeUpload}
        accept={SUPPORTED_FORMATS.join(",")}
        onChange={(e) => {
          if (e.file && e.file.status === "done") {
            onUploadFinish(e.file.response);
          }
        }}
        maxCount={1}
      >
        <Button block type="primary">
          {t("parcourir")}
        </Button>
      </Upload>
    </>
  );
}
