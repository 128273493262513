import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
type SucessPageProps = {
  type: "success";
  message: string;
  subMessage?: string;
  primaryButtonClick?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
};

type ErrorPageProps = {
  type: "error";
  message: string;
  subMessage?: string;
  primaryButtonClick?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
  errosTitle?: string;
  errors?: string[];
};

type ErrorCodePageProps = {
  type: "errorCode";
  statusCode: "403" | "404" | "500";
};

type StatusPageProps = SucessPageProps | ErrorCodePageProps | ErrorPageProps;

const StatusPage = (props: StatusPageProps) => {
  const { t } = useTranslation();
  if (props.type === "errorCode") {
    return (
      <Result
        status={props.statusCode}
        title={props.statusCode}
        subTitle={t("notFound.desole_page_exist_pas")}
        extra={<Button type="primary">{t("accueil")}</Button>}
      />
    );
  }
  return <div>{props.type}</div>;
};

export default StatusPage;
