import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

interface RouteTypeProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: RouteTypeProps) => {
  const { isAuthenticated } = useAuth();
  return <>{isAuthenticated ? children : <Navigate to="/login" replace />}</>;
};

const AuthRoute = ({ children }: RouteTypeProps) => {
  const { isAuthenticated } = useAuth();
  return <>{isAuthenticated ? <Navigate to="/profile" replace /> : children}</>;
};
const SessionRecoursRoute = ({ children }: RouteTypeProps) => {
  const { sessionInfo } = useAuth();
  return (
    <>
      {!sessionInfo.recoursOuvert ? (
        <Navigate to="/recoursnonautorisee" replace />
      ) : (
        children
      )}
    </>
  );
};
const SessionInscriptionRoute = ({ children }: RouteTypeProps) => {
  const { sessionInfo } = useAuth();
  return (
    <>
      {!sessionInfo.inscriptionOuverte ? (
        <Navigate to="/inscriptionnonautorisee" replace />
      ) : (
        children
      )}
    </>
  );
};
const SessionInscriptionAutoriseeRoute = ({ children }: RouteTypeProps) => {
  const { sessionInfo } = useAuth();
  return (
    <>
      {sessionInfo.inscriptionOuverte ? (
        <Navigate to="/inscription" replace />
      ) : (
        children
      )}
    </>
  );
};
const SessionRecoursAutoriseeRoute = ({ children }: RouteTypeProps) => {
  const { sessionInfo } = useAuth();
  return (
    <>
      {sessionInfo.recoursOuvert ? (
        <Navigate to="/recours" replace />
      ) : (
        children
      )}
    </>
  );
};
export {
  PrivateRoute,
  AuthRoute,
  SessionRecoursRoute,
  SessionInscriptionRoute,
  SessionInscriptionAutoriseeRoute,
  SessionRecoursAutoriseeRoute,
};
