import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import { FloatButton } from "antd/lib";
import MobileHeader from "../../components/NavBar/MobileNav";
import { useState } from "react";
import NavBar from "../../components/NavBar/Nav";
import Footer from "../../components/Footer/Footer";

const BaseLayout = () => {
  const [state, setState] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setState(window.innerWidth);
  };
  window.addEventListener("resize", handleWindowResize);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {state > 930 ? (
        <>
          <NavBar />
        </>
      ) : (
        <MobileHeader />
      )}
      <Layout.Content
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Outlet />
      </Layout.Content>
      <Footer />
      <FloatButton.BackTop className={"float-btn"} />
    </Layout>
  );
};
export default BaseLayout;
