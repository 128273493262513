import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '../translations';

i18n
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // debug: true,
        lng: 'ar',
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: { ...translations }
    });

export default i18n;