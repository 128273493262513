export const sexe = [
    { value: 1, label: 'sexe.homme' },
    { value: 2, label: 'sexe.femme' },
]

export const situation_familiale = [
    { value: 2, label: 'situation_familiale.celibataire' },
    { value: 1, label: 'situation_familiale.marie', enfant: true },
    { value: 3, label: 'situation_familiale.divorce', enfant: true },
    { value: 4, label: 'situation_familiale.veuf', enfant: true },
]
export const situation_professionnelle = [
    { value: 1, label: 'situation_professionnelle.salarie' },
    { value: 2, label: 'situation_professionnelle.retraite' },
]
export const situation_professionnelle_conjoint = [
    { value: 1, label: 'situation_professionnelle_conjoint.actif' },
    { value: 2, label: 'situation_professionnelle_conjoint.sans' },
    { value: 3, label: 'situation_professionnelle_conjoint.militaire' },

]

export const oui_non = [
    { value: 0, label: 'inscription.non' },
    { value: 1, label: 'inscription.oui' },
]

