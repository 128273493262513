const handleClickScrollToContactUs = (elem:string) => {
    const element = document.getElementById(elem);
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });


    }
};

export default  handleClickScrollToContactUs;