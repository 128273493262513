import { CSSProperties } from "react";
import { COLOR_PRIMARY2, COLOR_PRIMARY_LIGHT } from "../../config/constants";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    backgroundColor: COLOR_PRIMARY_LIGHT,
    minHeight: "calc(50vh)",
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "20px 0",
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",

    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1rem",
    lineHeight: 1.6,
  },
  heroWelcome: {
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
};
const RuralConstructionSectionMontant = () => {
  const { t, i18n } = useTranslation();

  return (
    <Row
      style={styles.rowContainer}
      justify="center"
      id="montant_aide_financiere"
    >
      <Col style={styles.colContainer} xs={22} lg={12}>
        <Space align="start" direction="vertical" size="small">
          <h5
            style={{
              textAlign: i18n.language === "fr" ? "left" : "right",
              ...styles.heroWelcome,
            }}
          >
            {t("rural_construction_montant.title")}
          </h5>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("rural_construction_montant.body")}
          </p>
        </Space>
      </Col>
      <Col style={{ ...styles.secondColContainer }}>
        <img
          src={window.location.origin + "/HouseMoney.png"}
          alt="fnpos"
          width={"400px"}
          height={"400px"}
        />
      </Col>
    </Row>
  );
};

export default RuralConstructionSectionMontant;
