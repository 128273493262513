import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Result,
  Select,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { RegisterStepProps } from ".";

const ConfirmationStep = ({
  lastStep,
  confirmed,
  setConfirmed,
}: RegisterStepProps) => {
  const { t, i18n } = useTranslation();
  function handleCheckChange(ev: any) {
    setConfirmed(!ev.target.checked);
  }
  return (
    <div
      style={
        !lastStep
          ? {
              display: "none",
            }
          : {}
      }
    >
      <Result
        style={{
          padding: 0,
          paddingBottom: 20,
        }}
        status="info"
        title={
          <Typography.Title level={3}>
            {t("inscription.confirmation.title")}
          </Typography.Title>
        }
        // subTitle={
        //   <Typography.Title level={4} type="danger">
        //     {t("inscription.confirmation.desc")}
        //   </Typography.Title>
        // }
        // extra={[
        //     <Button type="primary" key="console">
        //         Go Console
        //     </Button>,
        //     <Button key="buy">Buy Again</Button>,
        // ]}
      >
        <div className="desc">
          <Typography.Paragraph>
            <Typography.Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              {t("inscription.confirmation.info")}
            </Typography.Text>
            <br />
            <br />
            <Typography.Text
              style={{
                fontSize: 16,
              }}
            >
              {t("inscription.confirmation.confirmation_message")}
            </Typography.Text>
            <br />
            <br />
            <Typography.Text
              strong
              type={"danger"}
              style={{
                fontSize: 16,
              }}
            >
              {t("observation") + ": "}
            </Typography.Text>

            <Typography.Text
              style={{
                fontSize: 16,
              }}
            >
              {t("inscription.confirmation.remarque")}
            </Typography.Text>
          </Typography.Paragraph>
          <Form.Item
            name="confirm_form"
            valuePropName="checked"
            validateTrigger="onChange"
            rules={[
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(t("messages.champ_obligatoire"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Checkbox onClick={handleCheckChange}>
              {t("inscription.confirmation.confirm")}
            </Checkbox>
          </Form.Item>
        </div>
      </Result>
    </div>
  );
};

export default ConfirmationStep;
