import { Button, Result } from "antd";
import { useAuth } from "../../auth/authContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export default function RecoursNonAutorisee() {
  const { sessionInfo } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title={
        i18n.language === "ar"
          ? sessionInfo.messageRecoursNonAutoriseAr
          : sessionInfo.messageRecoursNonAutoriseLt
      }
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          {t("accueil")}
        </Button>
      }
    />
  );
}
