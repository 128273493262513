import { Col, Form, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDemandeProfile, getTypeDemandes } from "../../api/demande";
import ContentHeader from "../../components/ContentHeader";
import { IProfileData } from "../../types/profile";
import LeftSide from "./LeftSide";
import TabsForm from "./TabsForm";
import { useLocation } from "react-router-dom";
import RecoursPage from "../RecoursPage/Recours";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getAllWilayas, getCommunes, getWilayas } from "../../api/wilayas";
import { ICommune, ITypeDemande, IWilaya } from "../../types/types";
import UpdateDemnde from "./UpdateDemande";
import dayjs from "dayjs";
import UpdateConjoint from "./UpdateConjoint";
import UpdateDemandeur from "./UpdateDemandeur";


const leftSideSpan = {
  xs: 22,
  md: 9,
  lg: 9,
};

const tabsFormSpan = {
  xs: 22,
  md: 13,
  lg: 13,
};

const middleColSpan = {
  xs: 0,
  md: 1,
  lg: 1,
};

const ProfilePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [profile, setProfile] = useState<IProfileData>();
  const [allWilayas, setAllWilayas] = useState<IWilaya[]>([]);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [formDemande] = Form.useForm();
  const [formConjoint] = Form.useForm();
  const [formInfo] = Form.useForm();
  const [isModifierDemandeVisible, setIsModifierDemandeVisible] = useState<boolean>(false);
  const [typesDemande, setTypesDemande] = useState<ITypeDemande[]>([]);

  const [isModifierConjointVisible, setIsModifierConjointVisible] = useState<boolean>(false);
  const [isModifierInfoVisible, setIsModifierInfoVisible] = useState<boolean>(false);
  const [loadingDemandeurInfo, setLoadingDemandeurInfo] = useState(false);
  const [loadingConjointInfo, setLoadingConjointInfo] = useState(false);
  

  const [routeState, setRouteState] = useState(location.pathname);
  const [isUpdated, setIsUpdated] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const getWilayasAll = async () => {
    const allwilayas = await getAllWilayas();
    setAllWilayas(allwilayas);
    // setToken(token);
  }
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
    
      return;
    }
    const token =  await executeRecaptcha();
    const w = await getTypeDemandes(token);
    setTypesDemande(w);
  };
  useEffect(() => {
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const onWilayaChange = (value: number) => {
    formInfo.setFieldsValue({ demandeur: { communeNaissanceId: undefined } });
    return getCommunes(value).then((res) => setCommunes(res));
  };
  const onWilayaConjointChange = (value: number) => {

    formConjoint.setFieldsValue({ cojoint: { communeNaissanceId: undefined } });
    return getCommunes(value).then((res) => setCommunes(res));
  };
  useEffect(() => {
    getWilayasAll();
  }, []);
  useEffect(() => {
    
    getDemandeProfile().then((response) => {
      if (response) {
        setProfile(response);
      }
    });
  }, [isUpdated])
  function handleModifierDemande() {
    
     formDemande.setFieldsValue({typeDemandeId:profile?.currentDemande?.typeDemandeId,id:profile?.currentDemande?.id,dateDecisionCnl:dayjs(profile?.currentDemande?.dateDecisionCnl),numDecisionCnl:profile?.currentDemande?.numDecisionCnl,adresseConstructionFr:profile?.currentDemande?.adresseConstructionFr,adresseConstructionAr:profile?.currentDemande?.adresseConstructionAr,deuxiemeTrancheVersee:profile?.currentDemande?.deuxiemeTrancheVersee?1:0,projetFinalise:profile?.currentDemande?.projetFinalise?1:0,dateDelivancePermisConstruction:dayjs(profile?.currentDemande?.dateDelivancePermisConstruction)});
    setIsModifierDemandeVisible(true);
    
  }
  async function handleModifierConjoint() {
    
    
    if(profile?.conjointExiste) {
      setLoadingConjointInfo(true);
      await getCommunes(profile?.communeNaissance?.wilayaId!).then((res) => setCommunes(res)).finally(()=>setLoadingConjointInfo(false));

      formConjoint.setFieldsValue({
        cojoint: {

          ...profile.conjoint,
          demandeurId:profile.id,
          dateNaissance: dayjs(profile?.conjoint?.dateNaissance),
          wilayaNaissanceId:profile?.conjoint?.communeNaissance?.wilayaId
           
          
        },
      });
    } else {
      formConjoint.setFieldValue(['cojoint','demandeurId'],profile?.id);
    }
    
    setIsModifierConjointVisible(true);
  }
  async function handleModifierInfomrations () {
    setLoadingDemandeurInfo(true);
    
    await getCommunes(profile?.communeNaissance?.wilayaId!).then((res) => setCommunes(res)).finally(()=>setLoadingDemandeurInfo(false));
    formInfo.setFieldsValue({
      demandeur: {
        ...profile,
        dateNaissance: dayjs(profile?.dateNaissance),
        wilayaNaissanceId:profile?.communeNaissance?.wilayaId
         
        
      },
    });
    

     setIsModifierInfoVisible(true);
  }
  return (
    <>
      {routeState === "/profile" || routeState === "/profile/" ? (
        <ContentHeader title={t("espace_demandeur")} />
      ) : (
        <ContentHeader title={t("demande_recours")} />
      )}

      <Row
        justify="center"
        style={{
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <Col
          {...leftSideSpan}
          style={{
            padding: 15,
            backgroundColor: "#EEEEEE",
          }}
        >
          {profile ? (
            <LeftSide location={routeState} profile={profile} handleModifierDemande={handleModifierDemande} handleModifierConjoint={handleModifierConjoint} handleModifierInfomrations={handleModifierInfomrations} loadingDemandeurInfo={loadingDemandeurInfo} loadingConjointInfo={loadingConjointInfo} />
          ) : (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </Col>
        <Col {...middleColSpan} />
        <Col {...tabsFormSpan}>
          {(routeState === "/profile" || routeState === "/profile/") &&
            (profile ? (
              <TabsForm profile={profile} />
            ) : (
              <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </>
            ))}
          {!profile?.currentDemande?.recoursExist &&
            (routeState === "/recours" || routeState === "/recours/") &&
            (profile ? <RecoursPage /> : <></>)}
        </Col>
      </Row>
      <UpdateDemnde isUpdated={isUpdated} setIsUpdated={setIsUpdated} isVisible={isModifierDemandeVisible} form={formDemande} setIsVisible={setIsModifierDemandeVisible} typesDemande={typesDemande} />
      <UpdateConjoint situationProfessionnel={profile?.conjoint?.situationProfessionnelle} conjointExist={profile?.conjointExiste || false} isUpdated={isUpdated} setIsUpdated={setIsUpdated} wilayas={allWilayas} isVisible={isModifierConjointVisible} setIsVisible={setIsModifierConjointVisible} form={formConjoint} communes={communes} onWilayaChange={onWilayaConjointChange} />
      <UpdateDemandeur conjointExist={profile?.conjointExiste} isUpdated={isUpdated} setIsUpdated={setIsUpdated} wilayas={allWilayas} isVisible={isModifierInfoVisible} setIsVisible={setIsModifierInfoVisible} form={formInfo} communes={communes} onWilayaChange={onWilayaChange} />
    </>
  );
};

export default ProfilePage;
