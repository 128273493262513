import { Col, Row, Space, Typography } from "antd";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./../../auth/authContext";
import { COLOR_PRIMARY2 } from "../../config/constants";
// import Typewriter from "typewriter-effect";
import { Typewriter } from "react-simple-typewriter";
import Countdown from "react-countdown";

const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    backgroundColor: COLOR_PRIMARY2,

    minHeight: "calc(56vh)",
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 20,
    gap: 30,
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  heroText: {
    margin: "36px 0px 0px",
    color: "#fff",
    fontSize: "1.2rem",
    textAlign: "right",
    fontWeight: "300",
    lineHeight: 2,
  },
  heroWelcome: {
    lineHeight: 1.7,
    margin: 0,
    fontWeight: "bold",
    color: "#fff",
    fontSize: "1.5rem",
    textAlign: "center",
  },
};

const FullPageHeader = () => {
  const { t, i18n } = useTranslation();
  const { sessionInfo } = useAuth();
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return (
        <>
          <Typewriter
            words={[
              i18n.language === "ar"
                ? sessionInfo.messageAccueilAr || ""
                : sessionInfo.messageAccueilLt || "",
            ]}
            loop={0}
            cursor
            cursorStyle="|"
            typeSpeed={70}
            deleteSpeed={0}
            delaySpeed={1000}
          />
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
          <Typography.Title
            level={4}
            style={{ color: "white", fontWeight: "lighter" }}
          >
            {t("counter_down_title")}{" "}
          </Typography.Title>
          <div style={{ display: "flex", flexDirection: "row", gap: 40 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 55, maxHeight: 83 }}>{days}</span>
              <i style={{ fontSize: 15 }}>{t("counter.day")}</i>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 55, maxHeight: 83 }}>{hours}</span>
              <i style={{ fontSize: 15 }}>{t("counter.hour")}</i>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 55, maxHeight: 83 }}>{minutes}</span>
              <i style={{ fontSize: 15 }}>{t("counter.minute")}</i>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 55, maxHeight: 83 }}>{seconds}</span>
              <i style={{ fontSize: 15 }}>{t("counter.second")}</i>
            </div>
          </div>
        </>
      );
    }
  };

  const date = new Date(sessionInfo?.dateFin?.toString());

  return (
    <Row style={styles.rowContainer} justify="center">
      <Col style={styles.colContainer} xs={22} lg={12}>
        <Space direction="vertical" size="small">
          <h5
            style={{
              ...styles.heroWelcome,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("full_page_header.title")}{" "}
          </h5>

          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            <Countdown
              renderer={renderer}
              date={Date.now() + (date.getTime() - Date.now())}
            />
          </p>
        </Space>
      </Col>
      <Col style={{ ...styles.secondColContainer }}>
        <img
          src={window.location.origin + "/inscription.png"}
          alt="fnpos"
          width={"400px"}
          height={"220px"}
        />
      </Col>
    </Row>
  );
};

export default FullPageHeader;
