import { API } from "./instance";

const getCulture = (dir: "ltr" | "rtl") => (dir === "rtl" ? 1 : 2);

export const DownloadRecuInscription = (dir: "ltr" | "rtl") =>
  API.get<BlobPart>(`/documents/recuinscription?Culture=${getCulture(dir)}`, {
    responseType: "blob",
  })
    .then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "RecuInscription.pdf");
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch(() => false);

export const DownloadConvocation = (dir: "ltr" | "rtl") =>
  API.get<BlobPart>(`/documents/convocation?Culture=${getCulture(dir)}`, {
    responseType: "blob",
  })
    .then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Convocation.pdf");
      document.body.appendChild(link);
      link.click();
    })

    .catch(() => false);

export const downloadJustification = () =>
  API.get<BlobPart>(`/recours/download`, {
    responseType: "blob",
  })
    .then((res) => {
      let url = "";
      console.error(res.headers["content-type"]);
      const contentType = res.headers["content-type"].split("/")[1];

      url = window.URL.createObjectURL(
        new Blob([res.data], {
          type:
            contentType === "jpg" || contentType === "jpeg"
              ? "application/pdf"
              : "image/jpg",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        contentType === "jpg" || contentType === "jpeg"
          ? "Justification.jpg"
          : "Justification.pdf"
      );
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch(() => false);
