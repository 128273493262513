import ConfigProvider from "antd/es/config-provider";
import { defaultAppConfig } from "./config/ant.theme";
import { useTranslation } from "react-i18next";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import AuthProvider from "./auth/authContext";
import { useEffect } from "react";
import AxiosInterceptor from "./hoc/AxiosInterceptor";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "dayjs/locale/fr-ca";
import "dayjs/locale/ar";

import localeFr from "antd/locale/fr_CA";
import localeAr from "antd/lib/locale/ar_EG";

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const current = localStorage.getItem("fnpos_lang");
    if (current) {
      i18n.changeLanguage(current);
    }
  }, []);
  defaultAppConfig.direction = i18n.language === "ar" ? "rtl" : "ltr";
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
    >
      <ConfigProvider
        {...defaultAppConfig}
        locale={i18n.language === "ar" ? localeAr : localeFr}
      >
        <AuthProvider>
          <AxiosInterceptor>
            <RouterProvider router={router} />
          </AxiosInterceptor>
        </AuthProvider>
      </ConfigProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
