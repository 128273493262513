import { CSSProperties, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2 } from "../../config/constants";

import { IContact } from "../../types/contactez-nous";
import { sendMessage } from "../../api/contacteznous";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import handleClickScrollToContactUs from "../../helpers/scrollToView";
const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    alignItems: "center",
    minHeight: "calc(50vh)",
    paddingInline: 10,
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 20,
    gap: 30,
  },

  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1rem",
    textAlign: "right",
  },
  heroWelcome: {
    margin: 0,
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
    paddingBottom: "10px",
  },
};

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async (values: IContact) => {
    setLoading(true);
    if (!executeRecaptcha) {
      console.log("Recaptcha doesn't restored");
      Modal.error({
        title: t("error"),
        content: (
          <ul>
            <li>{t("captcha_error")}</li>
          </ul>
        ),
      });
      return;
    }

    try {
      const token = await executeRecaptcha();
      console.log("token has been restored from google");

      const res = await sendMessage({ captchaToken: token, ...values });
      console.log(res);
      if (res.success) {
        setTimeout(() => {
          Modal.success({
            title: t("operation_effectuee"),
            content: (
              <ul>
                <li>{t("contactez_nous_section.message_succes")}</li>
              </ul>
            ),
            direction: i18n.dir(),
          });
        }, 800);

        handleClickScrollToContactUs("navbar");
        form.resetFields();
      } else {
        Modal.error({
          title: t("operation_echouee"),
          content: (
            <ul>
              <li>{t("login.error")}</li>
            </ul>
          ),
          direction: i18n.dir(),
        });
      }
    } catch (err) {
      console.log("error in on Finish contactez nous section");
      if (err instanceof Error) {
        Modal.error({
          title: t("operation_echouee"),
          content: (
            <ul>
              <li>{t(err.message)}</li>
            </ul>
          ),
          direction: i18n.dir(),
        });
      } else {
        console.log("error in on Finish contactez nous section");

        Modal.error({
          title: t("operation_echouee"),
          content: (
            <ul>
              <li>{t(err as string)}</li>
            </ul>
          ),
          direction: i18n.dir(),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const colSpan = {
    xs: 24,
    md: 18,
    lg: 18,
    xl: 18,
    xxl: 18,
  };

  const colSpan2 = {
    xs: 24,
    md: 24,
    lg: 11,
    xl: 11,
    xxl: 11,
  };

  return (
    <>
      <Row
        id="contactez_nous"
        justify={"center"}
        style={{
          ...styles.rowContainer,
        }}
      >
        <Col {...colSpan} className={"contactez-nous"}>
          <p style={styles.heroWelcome}>{t("contactez_nous")}</p>
          <Form
            form={form}
            onFinish={onFinish}
            validateTrigger="onBlur"
            scrollToFirstError={{
              behavior: "smooth",
            }}
            validateMessages={{
              required: t("messages.champ_obligatoire").toString(),
            }}
          >
            <Row justify={"space-between"}>
              <Col {...colSpan2}>
                <Form.Item name="nomEtPrenom" rules={[{ required: true }]}>
                  <Input
                    size="large"
                    placeholder={t("contactez_nous_section.nom_prenom") || ""}
                  />
                </Form.Item>
              </Col>
              <Col {...colSpan2}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: t("messages.email_invalide")!,
                    },
                  ]}
                >
                  <Input
                    placeholder={t("contactez_nous_section.email") || ""}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="sujet" rules={[{ required: true }]}>
              <Input
                placeholder={t("contactez_nous_section.sujet") || ""}
                size="large"
              />
            </Form.Item>
            <Form.Item name="corpsMail" rules={[{ required: true }]}>
              <Input.TextArea
                style={{ height: "100px" }}
                placeholder={t("contactez_nous_section.message") || ""}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("envoyer")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ContactUs;
