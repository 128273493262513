import { Button, Col, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/authContext";
import { IRecoursForm } from "../../types/recours";
import FileUpload from "../../components/Upload";
import { CreateRecours } from "../../api/recours";
import { useNavigate } from "react-router-dom";

const RecoursPage = function () {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: IRecoursForm) => {
    setLoading(true);
    try {
      const res = await CreateRecours(values);
      console.log(res);
      if (res.success) {
        message.success(t("recours_success"));
        navigate("/profile");
      } else {
        Modal.error({
          title: t("login.error"),
          content: (
            <ul>
              {res.error.map((error, key) => (
                <li key={key}>{t(error)}</li>
              ))}
            </ul>
          ),
          direction: i18n.dir(),
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        Modal.error({
          title: t("error"),
          content: (
            <ul>
              <li>{t(err.message)}</li>
            </ul>
          ),
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const onUploadFinish = (val: string) => {
    form.setFieldValue("fileName", val);
  };

  const props = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (
    <>
      <Col>
        <Form
          onFinish={onFinish}
          labelCol={{ xs: 11, md: 12, xl: 9, xxl: 7 }}
          labelAlign="left"
          form={form}
          validateTrigger="onBlur"
          scrollToFirstError={{
            behavior: "smooth",
          }}
          validateMessages={{
            required: t("messages.champ_obligatoire").toString(),
          }}
        >
          <Form.Item
            name="description"
            label={t("justification")}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  } else {
                    if (value.length >= 2000) {
                      return Promise.reject(t("messages.max_length"));
                    } else {
                      return Promise.resolve();
                    }
                  }
                },
              },
            ]}
          >
            <Input.TextArea
              maxLength={1000}
              style={{ height: 300 }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="fileName"
            label={t("joinder_fichier")}
            rules={[{ required: true }]}
          >
            <FileUpload
              headers={props.headers}
              action={`${process.env.REACT_APP_API_URL}/recours/upload`}
              onUploadFinish={onUploadFinish}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              {t("envoyer")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      {/*</Row >*/}
    </>
  );
};

export default RecoursPage;
