import { IProfileData } from "../types/profile";
import { ICreateConjiont, ICreateData, IFormData, ITypeDemande, IUpdateConjoint, IUpdateDemande, IUpdateDemandeur } from "../types/types";
import { API } from "./instance";
import { getDataFromResponse, getErrorsFromResponse } from "./utils";

export const getTypeDemandes = (captchaToken: string) =>
  API.get<ITypeDemande[]>("/typedemandes/get", { params: { captchaToken } })
    .then((res) => res.data)
    .catch((ex) => []);

export const CreateDemande = (data: IFormData) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const formData: ICreateData = {
    ...data,
    dateDecisionCnl: data.dateDecisionCnl.format(DATE_FORMAT),
    dateDelivancePermisConstruction:
      data.dateDelivancePermisConstruction.format(DATE_FORMAT),
    demandeur: {
      ...data.demandeur,
      dateNaissance: data.demandeur.dateNaissance.format(DATE_FORMAT),
    },
    cojoint: data.cojoint
      ? {
          ...data.cojoint,
          dateNaissance: data.cojoint.dateNaissance.format(DATE_FORMAT),
          situationFamiliale: data.demandeur.situationFamiliale,
        }
      : undefined,
    deuxiemeTrancheVersee: Boolean(data.deuxiemeTrancheVersee),
    projetFinalise: Boolean(data.projetFinalise),
  };
  return API.post("/demandes/create", formData)
    .then((res) => getDataFromResponse(res.data))
    .catch((ex) => getErrorsFromResponse(ex));
};
export const UpdateDemande = (data:IUpdateDemande) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const formData = {
    ...data,
    dateDecisionCnl: data.dateDecisionCnl.format(DATE_FORMAT),
    dateDelivancePermisConstruction:
      data.dateDelivancePermisConstruction.format(DATE_FORMAT),
      deuxiemeTrancheVersee: Boolean(data.deuxiemeTrancheVersee),
      projetFinalise: Boolean(data.projetFinalise),
  }
  return API.put("/demandes/demande/"+data.id,formData)
      .then(res=>res)
      .catch(err=>err);

}
export const UpdateDemandeurInfo = (data:IUpdateDemandeur) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const formData = {
    ...data,
    dateNaissance: data.dateNaissance.format(DATE_FORMAT)
  }
  return API.put("/demandes/demandeur/"+data.id,formData)
      .then(res=>res)
      .catch(err=>err);
}
export const UpdateConjointInfo = (data:IUpdateConjoint) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const formData = {
    ...data,
    dateNaissance: data.dateNaissance.format(DATE_FORMAT)
  }
  return API.put("/demandes/conjoint/"+data.id,formData)
      .then(res=>res)
      .catch(err=>err);
}
export const CreateConjoint = (data:ICreateConjiont) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const formData = {
    ...data,
    dateNaissance: data.dateNaissance.format(DATE_FORMAT)
  }
  return API.post("/demandes/createconjoint/"+data.demandeurId,formData)
      .then(res=>res)
      .catch(err=>err);
}
export const checkNewEmail = (email: string, captchaToken: string) =>
  API.get<boolean>("/demandes/isnewemail", {
    params: {
      email,
      captchaToken,
    },
  })
    .then((res) => res.data)
    .catch((ex) => false);

export const checkIsValidNss = (nss: string, captchaToken: string) =>
  API.get<boolean>("/demandes/isvalidnss", {
    params: {
      nss,
      captchaToken,
    },
  })
    .then((res) => res.data)
    .catch((ex) => false);

export const getDemandeProfile = () =>
  API.get<IProfileData>("/demandes/getprofile")
    .then(({ data }) => {
      return data;
    })
    .catch((ex) => {
      return null;
    });
