import {
  Button,
  Col,
  Divider,
  Dropdown,
  Layout,
  Row,
  Typography,
  Tag,
  Space,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  BUTTON_STYLE,
  COLOR_PRIMARY2,
  COLOR_SECONDARY,
} from "../../../config/constants";
import { useAuth } from "../../../auth/authContext";
import { useTranslation } from "react-i18next";
import { getNavItems } from "../navItems";
import AngleDown from './../../../assets/angle-down.svg'


const { Text } = Typography;

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    height: "auto",
    minHeight: "20vh",
  },
  fnposLogo: {
    width: "150px",
    height: "150px",
    backgroundImage: "url(./logo-fnpos.png)",
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  aps: {
    width: "150px",
    height: "156px",
    backgroundImage: "url(./logo_ministere.png)",
    backgroundSize: "95%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: "10px",
  },
  divider: {
    minWidth: "100%",
    margin: "0",
    backgroundColor: "#ccc",
    height: "3px",
  },
  items: {
    width: "80%",
    textAlign: "center" as "center",
    color: `${COLOR_PRIMARY2}`,
  },
  parag: {
    margin: "0",
    lineHeight: "2",
  },
  btn: {
    border: "none",
    color: COLOR_PRIMARY2,
    boxShadow: "none",
    fontWeight: "bold",
    fontSize: 16,
    margin: 0,
    cursor: "pointer",
  },
};

const NavBar = () => {
  const { isAuthenticated, logout, displayName, sessionInfo } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };
  return (
    <Layout.Header style={styles.header} id={"navbar"}>
      <div style={styles.fnposLogo}></div>

      <div style={styles.items}>
        <p
          style={{
            ...styles.parag,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          {t("republique_algerienne")}
        </p>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}

        {/* <p
          style={{
            ...styles.parag,
          }}
        >
          {t("navbar.fnpos1")}
          {t("navbar.fnpos2")}
        </p> */}

        <p
          style={{
            ...styles.parag,
          }}
        >
          {t("ministere_du_travail")}
        </p>
        <p
          style={{
            ...styles.parag,
          }}
        >
          {t("fnpos")}
        </p>
        {/* </div> */}
        <div></div>
        <div>
          <Divider style={styles.divider} />
        </div>
        <Row justify={"space-between"}>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <Button style={styles.btn}>{t("accueil")}</Button>
            </Link>
            <Dropdown
              className="menu"
              menu={{
                items: getNavItems("desktop", () => {}, t),
              }}
              trigger={["click"]}
            >
              <h5 style={styles.btn} onClick={(e) => e.preventDefault()}>
                <Button style={styles.btn} className="drop-down-nav">
                  {t("a_propos_du_service")}
                  <span
                    role={"img"}
                    aria-label={"down"}
                    className={"anticon anticon-down"}
                  >
                    <img src={AngleDown} />
                    {/* <CaretDownOutlined style={{ color: "#000" }} /> */}
                  </span>
                </Button>
              </h5>
            </Dropdown>
            <Link to="/#contactez_nous" style={styles.btn}>
              {t("contactez_nous")}
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Space>
              {isAuthenticated ? (
                <Tag color={COLOR_SECONDARY}>
                  <Button
                    style={{
                      backgroundColor: COLOR_SECONDARY,
                      color: "#fff",
                      border: "none",
                    }}
                    onClick={() => navigate("profile")}
                  >
                    <Text underline style={{ color: "white" }}>
                      {t("profile_utilisateur")}
                    </Text>
                  </Button>
                </Tag>
              ) : sessionInfo.inscriptionOuverte ? (
                <Button
                  type={"default"}
                  style={BUTTON_STYLE}
                  onClick={() => navigate("inscription")}
                >
                  {t("sinscrire")}
                </Button>
              ) : (
                <></>
              )}

              {!isAuthenticated && (
                <Link to="/login">
                  <Button type="default" style={BUTTON_STYLE}>
                    {t("connexion")}
                  </Button>
                </Link>
              )}
              {isAuthenticated && (
                <Tag color={COLOR_SECONDARY}>
                  {t("navbar.bienvenue")} : {displayName}
                  <Button
                    style={{
                      backgroundColor: COLOR_SECONDARY,
                      color: "#fff",
                      border: "none",
                    }}
                    onClick={handleLogout}
                  >
                    <Text underline style={{ color: "white" }}>
                      {t("deconnexion")}
                    </Text>
                  </Button>
                </Tag>
              )}

              <Button
                style={{ fontWeight: "bold" }}
                onClick={() => {
                  i18n.changeLanguage(i18n.language === "fr" ? "ar" : "fr");
                  localStorage.setItem(
                    "fnpos_lang",
                    i18n.language === "fr" ? "fr" : "ar"
                  );
                }}
              >
                {i18n.language === "fr" ? "عربي" : "Français"}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>

      <div style={styles.aps}></div>
    </Layout.Header>
  );
};

export default NavBar;
