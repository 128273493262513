import { Modal } from "antd";
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { authLogin } from "../api/auth";
import { getSessionInfo } from "../api/session";
import { IAppToken, ILoginCredentials } from "../types/types";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ISession } from "../types/session";
import Spinner from "./../assets/spinner.svg";

interface AuthState {
  isAuthenticated: boolean;
  displayName: string;
}

interface AuthContextProps extends AuthState {
  login: (credintials: ILoginCredentials) => void;
  logout: () => void;
  loading: boolean;
  token?: string;
  sessionInfo: ISession;
}
const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  displayName: "",
  login: () => {},
  logout: () => {},
  sessionInfo: {} as ISession,
  loading: true,
});

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nomAr, setNomAr] = useState("");
  const [nomLt, setNomLt] = useState("");
  const [token, setToken] = useState("");
  const [sessionInfo, setSessionInfo] = useState<ISession>({} as ISession);
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const displayName = i18n.language === "ar" ? nomAr : nomLt;

  const login = (credintials: ILoginCredentials) => {
    setLoading(true);
    if (executeRecaptcha) {
      executeRecaptcha().then((captchaToken) =>
        authLogin(
          (credintials = {
            ...credintials,
            captchaToken,
          })
        )
          .then((res) => {
            if (res.success) {
              setToken(res.data);
              let token_decoded: IAppToken = jwt_decode(res.data);
              setNomAr(token_decoded.nomAr);
              setNomLt(token_decoded.nomLt);
              setIsAuthenticated(true);
            } else {
              Modal.error({
                title: t("login.error"),
                content: (
                  <ul>
                    <li>{t("login.error_creds")}</li>
                  </ul>
                ),
                direction: i18n.dir(),
              });
            }
          })
          .finally(() => setLoading(false))
      );
    } else {
      Modal.error({
        title: t("error"),
        content: (
          <ul>
            <li>{t("captcha_error")}</li>
          </ul>
        ),
      });
    }
  };

  const logout = () => {
    window.location.replace("/login");
  };

  useEffect(() => {
    getSessionInfo()
      .then((res) => {
        if (res.success) {
          setSessionInfo(res.data.data);
        }
      })
      .finally(() => {
        setIsAppLoading(false);
      });
  }, []);
  const AppSpin = () => (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Spin /> */}
      <img src={Spinner} width={150} height={150} alt="fnpos-spinner" />
    </div>
  );
  return (
    <>
      <AuthContext.Provider
        value={{
          token,
          login,
          logout,
          displayName,
          loading,
          isAuthenticated,
          sessionInfo,
        }}
      >
        {isAppLoading ? <AppSpin /> : children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
