export const stepFields: { [key: string]: string[] | string[][] } = {
    'nss':[        
        ['demandeur', 'dateNaissance'],
        ['demandeur', 'numSecuriteSociale'],
    ],
    'informations': [
        ['demandeur', 'genre'],
        ['demandeur', 'situationFamiliale'],
        ['demandeur', 'nbrEnfants'],
        ['demandeur', 'situationProfessionelle'],
        ['demandeur', 'revenuMensuel'],
        ['demandeur', 'anneeExperienceProfessionnelle'],
        ['demandeur', 'moisExperienceProfessionnelle'],
        ['demandeur', 'nomLt'],
        ['demandeur', 'nomAr'],
        ['demandeur', 'prenomLt'],
        ['demandeur', 'prenomAr'],
        ['demandeur', 'wilayaNaissanceId'],
        ['demandeur', 'communeNaissanceId'],
        ['demandeur', 'numActeNaissance'],
        ['demandeur', 'numIdentificationNational'],
        ['demandeur', 'prenomPereLt'],
        ['demandeur', 'prenomPereAr'],
        ['demandeur', 'nomMereLt'],
        ['demandeur', 'nomMereAr'],
        ['demandeur', 'prenomMereLt'],
        ['demandeur', 'prenomMereAr'],
        ['demandeur', 'numTel'],
        ['demandeur', 'email']
    ],
    'cojoint': [
        ['cojoint', 'nomAr'],
        ['cojoint', 'nomLt'],
        ['cojoint', 'prenomAr'],
        ['cojoint', 'prenomLt'],
        ['cojoint', 'dateNaissance'],
        ['cojoint', 'wilayaNaissanceId'],
        ['cojoint', 'communeNaissanceId'],
        ['cojoint', 'numActeNaissance'],
        ['cojoint', 'numIdentificationNational'],
        ['cojoint', 'prenomPereAr'],
        ['cojoint', 'prenomPereLt'],
        ['cojoint', 'nomMereAr'],
        ['cojoint', 'nomMereLt'],
        ['cojoint', 'prenomMereAr'],
        ['cojoint', 'prenomMereLt'],
        ['cojoint', 'situationProfessionelle'],
        ['cojoint', 'revenuMensuel'],
        ['cojoint', 'numSecuriteSociale'],

    ],
    'demande': [
        'typeDemandeId',
        'dateDecisionCnl',
        'numDecisionCnl',
        'wilayaId',
        'dateDelivancePermisConstruction',
        'projetFinalise',
        'deuxiemeTrancheVersee'
    ]
}
