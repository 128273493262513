import { Alert, Button, Col, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ILoginCredentials } from "../../types/types";
import { useAuth } from "../../auth/authContext";
import { Link, useNavigate } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader";
import { authResetPassword } from "../../api/auth";
import { useState } from "react";

const ForgetPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: { email: string }) => {
    setLoading(true);
    authResetPassword(values.email)
      .then((res) => {
        console.log(res);
        if (res.success) {
          navigate("/login", { replace: true });
          Modal.success({
            title: `${t("login.reset_password")}`,
            content: `${t("login.reset_password_success")}`,
            direction: i18n.dir(),
          });
        } else {
          setAlert(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const colSpan = {
    xs: 20,
    md: 16,
    lg: 14,
    xl: 9,
    xxl: 8,
  };

  return (
    <>
      <ContentHeader title={t("formulaire_mot_de_passe_oublie")} />
      <Row justify="center" style={{ flex: 1 }}>
        <Col
          {...colSpan}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {alert && (
            <Alert
              message={t("error")}
              description={t("login.error_reset_password_email_not_found")}
              type="error"
              showIcon
              closable
              afterClose={() => setAlert(false)}
            />
          )}
          <Form
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            labelAlign="left"
            form={form}
            validateTrigger="onBlur"
            scrollToFirstError={{
              behavior: "smooth",
            }}
            validateMessages={{
              required: t("messages.champ_obligatoire").toString(),
            }}
          >
            <Form.Item
              name="email"
              label={t("contactez_nous_section.email")}
              rules={[
                { required: true },
                {
                  type: "email",
                  message: t("messages.email_invalide").toString(),
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                {t("login.reset_password")}
              </Button>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Link to="/login">
                <Button
                  style={{
                    padding: 0,
                  }}
                  type="link"
                >
                  {t("login.login")}
                </Button>
              </Link>
              <Link to="/inscription">
                <Button
                  style={{
                    padding: 0,
                  }}
                  type="link"
                >
                  {t("login.non_inscrit")}
                </Button>
              </Link>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ForgetPasswordPage;
