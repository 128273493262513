import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "antd/dist/reset.css";
import "./config/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if (process.env.NODE_ENV==="production") {
console.log = () => {}
console.error = () => {}
console.debug = () => {}
}
root.render(
//  <React.StrictMode>
    <App />
//  </React.StrictMode>
);
