import {
  Button,
  Col,
  Form,
  Row,
  Steps,
  Typography,
  message,
  Modal,
  Result,
} from "antd";
import { useTranslation } from "react-i18next";
import { useState, CSSProperties } from "react";
import InformationsStep from "./InformationsStep";
import { FormInstance } from "antd/lib/form/Form";
import DemandeStep from "./DemandeStep";
import { stepFields } from "./stepFields";
import { FieldData } from "rc-field-form/lib/interface";
import { IFormData, IWilaya } from "../../types/types";
import { useEffect, useRef } from "react";
import { getAllWilayas, getWilayas } from "../../api/wilayas";
import ConjointStep from "./ConjointStep";
import SocialStep from "./SocialStep";
import ConfirmationStep from "./ConfirmationStep";

import { CreateDemande } from "../../api/demande";
import { Link } from "react-router-dom";

import AnimatedSuccess from "./check-mark-success-animation.json";
import Lottie from "lottie-react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { COLOR_PRIMARY2 } from "../../config/constants";

const styles: { [key: string]: CSSProperties | undefined } = {
  headerRowContainer: {
    backgroundColor: COLOR_PRIMARY2,
  },
  title: {
    color: "white",
    paddingTop: 20,
    paddingBottom: 6,
  },

  rowContainer: {
    backgroundImage: "url(./Bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "20px 0",
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "#fff",
    fontSize: "1.4rem",
    textAlign: "center",
  },
  heroWelcome: {
    margin: 0,
    color: "#fff",
    fontSize: "1.8rem",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#c86a31",
  },
};

export interface RegisterStepProps {
  form: FormInstance;
  current: number;
  wilayas?: IWilaya[];
  conjointExist?: boolean;
  lastStep?: boolean;
  confirmed?: boolean;
  setConfirmed?: any;
}

const RegisterPage = () => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [conjointExist, setConjointExist] = useState(true);
  const [wilayas, setWilayas] = useState<IWilaya[]>([]);
  const [allWilayas, setAllWilayas] = useState<IWilaya[]>([]);
  const [validating, setValidating] = useState(false);
  const [success, setSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [confirmed, setConfirmed] = useState<boolean>(true);

  const scrollDiv = useRef<HTMLDivElement>(null);
  const resultDiv = useRef<HTMLDivElement>(null);
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("register");
    // setToken(token);
    const w = await getWilayas(token);
    const allwilayas = await getAllWilayas();
    setAllWilayas(allwilayas);
    setWilayas(w);
    // .then((res) => setWilayas(res))
  };
  useEffect(() => {
    if (!executeRecaptcha) {
      // Modal.error({
      //   title: t("error"),
      //   content: (
      //     <ul>
      //       <li>{t("captcha_error")}</li>
      //     </ul>
      //   ),
      // });
      return;
    }

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    if (resultDiv.current)
      resultDiv.current.scrollIntoView({ behavior: "smooth" });
  }, [resultDiv.current]);

  const steps = [
    {
      key: "nss",
      title: t("inscription.steps.iss"),
    },
    {
      key: "informations",
      title: t("inscription.steps.ren_personel"),
    },
    {
      key: "cojoint",
      title: t("inscription.steps.isc"),
    },
    {
      key: "demande",
      title: t("inscription.steps.formulaire_candidature"),
    },
    {
      key: "confirmation",
      title: t("inscription.steps.validation_donnees"),
    },
  ];

  const stepItems = steps.filter(
    (item) => conjointExist || item.key != "cojoint"
  );
  const getCurrentStepFields = () => {
    const currentStep = stepItems[current];
    return stepFields[currentStep.key];
  };

  const next = () => {
    setValidating(true);
    const fields = getCurrentStepFields();
    form
      .validateFields(fields)
      .then(() => {
        setCurrent(current + 1);
        scrollDiv.current?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((ex) => {
        let errorFields = ex.errorFields;
        if (errorFields) {
          const firstField = errorFields[0].name;
          form.scrollToField(firstField, {
            behavior: "smooth",
            block: "center",
          });
        }
        message.error(t("formulaire_inscription_description"));
      })
      .finally(() => {
        setValidating(false);
      });
  };

  const prev = () => {
    if (current > 0) {
      setCurrent(current - 1);
      scrollDiv.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onFinish = (values: IFormData) => {
//    setLoading(true);
    executeRecaptcha &&
      executeRecaptcha()
        .then((captchaToken) => {
          return CreateDemande({ ...values, captchaToken }).then((res) => {
            if (res.success) {
              setSuccess(true);
            } else {
              let errors = res.error;
              Modal.error({
                title: t("erreur"),
                content: (
                  <ul>
                    {errors.map((e, i) => (
                      <li key={i}>{t(e)}</li>
                    ))}
                  </ul>
                ),
                direction: i18n.dir(),
              });
            }
          }).finally(()=>{
		setLoading(false);
	});
	});
        

};

  const onFieldsChange = (
    changedFields: FieldData[],
    allFields: FieldData[]
  ) => {
    let situationFamiliale = changedFields.find((f) =>
      `${f.name}`.includes("situationFamiliale")
    );
    if (situationFamiliale && situationFamiliale.value) {
      setConjointExist(situationFamiliale.value == 1);
    }
  };

  const spanSteps =
    stepItems.length == steps.length
      ? {
          xs: 22,
          md: 18,
          xl: 20,
          xxl: 14,
        }
      : { xs: 22, md: 18, xl: 16, xxl: 12 };

  return (
    <>
      <Row justify="center" style={styles.headerRowContainer}>
        <Col span={24}>
          <Row justify={"center"}>
            <Col>
              <Typography.Title style={styles.title} level={3}>
                {t("formulaire_inscription")}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
      {!success ? (
        <>
          <Row
            justify="center"
            style={{
              display: "flex",
              padding: "50px 0",
            }}
          >
            <Col {...spanSteps} ref={scrollDiv}>
              <Typography.Title
                level={5}
                style={{
                  color: "black",
                  fontWeight: "lighter",
                  textAlign: "center",
                }}
              >
                {t("formulaire_inscription_sous_titre1")}{" "}
                <Link
                  to="/#conditions_eligibilite"
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {t("formulaire_inscription_link")}
                </Link>{" "}
                {t("formulaire_inscription_sous_titre2")}
              </Typography.Title>
              <Steps
                labelPlacement="vertical"
                items={stepItems}
                current={current}
                style={{
                  marginTop: 30,
                }}
              />
            </Col>
          </Row>

          <Row justify="center">
            <Col xs={23} md={18} lg={16} xl={12} xxl={10}>
              <Form
                onFinish={onFinish}
                labelCol={{ span: 9 }}
                labelAlign="left"
                form={form}
                size="large"
                onFieldsChange={onFieldsChange}
                validateTrigger="onBlur"
                validateMessages={{
                  required: t("messages.champ_obligatoire").toString(),
                }}
              >
                <SocialStep form={form} current={current} wilayas={wilayas} />
                <InformationsStep
                  form={form}
                  current={current}
                  wilayas={allWilayas}
                />
                {conjointExist && (
                  <ConjointStep
                    form={form}
                    current={current}
                    wilayas={allWilayas}
                    conjointExist={conjointExist}
                  />
                )}
                <DemandeStep
                  form={form}
                  current={current}
                  wilayas={wilayas}
                  conjointExist={conjointExist}
                />
                <ConfirmationStep
                  form={form}
                  current={current}
                  wilayas={wilayas}
                  lastStep={current === stepItems.length - 1}
                  confirmed={confirmed}
                  setConfirmed={setConfirmed}
                />
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 10,
                      margin: "15px 0",
                    }}
                  >
                    <Button
                      onClick={prev}
                      type="primary"
                      ghost
                      disabled={current == 0 || loading}
                    >
                      {t("inscription.precedent")}
                    </Button>

                    <Button
                      loading={validating}
                      onClick={next}
                      type="primary"
                      disabled={current == stepItems.length - 1 || loading}
                    >
                      {t("inscription.suivant")}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item>
                  {current == stepItems.length - 1 && (
                    <Button
                      loading={loading}
                      block
                      disabled={confirmed}
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
			setLoading(true);
			form.validateFields();
                        console.log(confirmed);
                      }}
                    >
                      {t("inscription.sinscrire")}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        <Row
          justify="center"
          ref={resultDiv}
          style={{
            display: "flex",
            padding: "35px 0",
            height: "100vh",
          }}
        >
          <Col>
            <Result
              status="success"
              icon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie
                    animationData={AnimatedSuccess}
                    loop={false}
                    style={{
                      width: 250,
                    }}
                  />
                </div>
              }




title={t("inscription.confirmation_success.title")}
              subTitle={t("inscription.confirmation_success.subTitle")}
              extra={[
                <Link to="/">
                  <Button type="primary" size="large">
                  {t("inscription.confirmation_success.go_back")}
                  </Button>
                </Link>,
              ]}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default RegisterPage;
