import { CSSProperties } from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2 } from "../../config/constants";
const styles: { [key: string]: CSSProperties | undefined } = {
  rowContent: {
    backgroundColor: "rgba(0,0,0,.6)",
    backgroundImage: "url('condi.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity: 0.3,
    backdropFilter: "grayscale(1) contrast(3) blur(1px)",
  },
  rowContainer: {
    minHeight: "calc(60vh)",
    padding: 20,
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 20,
    gap: 30,
  },

  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",
  },
  heroWelcome: {
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
};

const TermsOfUseSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <Row
      style={styles.rowContainer}
      justify="center"
      id="conditions_eligibilite"
    >
      <Col span={24} style={styles.colContainer} xs={22} lg={20}>
        <Row>
          <Col>
            <h5
              style={{
                ...styles.heroWelcome,
                textAlign: i18n.language === "fr" ? "left" : "right",
              }}
            >
              {t("conditions_eligibilite.title")}{" "}
            </h5>
            <p
              style={{
                ...styles.heroText,
                textAlign: i18n.language === "fr" ? "left" : "right",
              }}
            >
              {t("conditions_eligibilite.info")}
            </p>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={12} xs={24}>
            <Space direction="vertical" size="small">
              <ul style={{ lineHeight: 1.6 }}>
                <Space direction={"vertical"}>
                  <li>{t("conditions_eligibilite.body.cond1")}</li>
                  <li>{t("conditions_eligibilite.body.cond2")}</li>

                  <li>{t("conditions_eligibilite.body.cond3")}</li>
                  <li>{t("conditions_eligibilite.body.cond4")}</li>
                </Space>
              </ul>
            </Space>
          </Col>
          <Col md={12} xs={24}>
            <Space direction="vertical" size="large">
              <ul style={{ lineHeight: 1.6 }}>
                <Space direction={"vertical"}>
                  <li>
                    {t("conditions_eligibilite.body.cond5.title")}
                    <ul
                      style={{
                        listStyle: "none",
                        fontWeight: "bold",
                      }}
                    >
                      <li>
                        - {t("conditions_eligibilite.body.cond5.steps.step1")}
                      </li>
                      <li>
                        - {t("conditions_eligibilite.body.cond5.steps.step2")}
                      </li>
                      <li>
                        - {t("conditions_eligibilite.body.cond5.steps.step3")}
                      </li>

                      <li>
                        - {t("conditions_eligibilite.body.cond5.steps.step4")}
                      </li>
                    </ul>
                  </li>
                  <li>{t("conditions_eligibilite.body.cond6")}</li>
                </Space>
              </ul>
            </Space>
          </Col>
          {/* <Col md={6} xs={24}>
            <div>

            <img
              src={ConditionImg}
              alt="fnpos conditions eligible"
              width={400}
            />
            </div>
            
            
          </Col> */}
        </Row>
      </Col>
      <Col
        lg={16}
        md={16}
        xs={20}
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: 10,
          border: "1px solid red",
        }}
      >
        <h5
          style={{
            ...styles.heroWelcome,
            textAlign: "center",
            padding: 10,
            color: "red",
          }}
        >
          {t("important")}
        </h5>
        <p style={{ textAlign: "center", lineHeight: 1.6, fontWeight: "bold" }}>
          {t("conditions_eligibilite.body.important")}{" "}
        </p>
      </Col>
      {/* </div> */}
    </Row>
  );
};

export default TermsOfUseSection;
