import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader";
import { authNewPassword } from "../../api/auth";
import { useLayoutEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { message } from "antd/lib";

const ResetPasswordPage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [params] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  useLayoutEffect(() => {
    if (!params.get("email") || !params.get("token")) {
      navigate("/404");
    }
  });

  const onFinish = (values: {
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    let email = params.get("email") || "";
    let token = params.get("token") || "";
    setLoading(true);
    if (executeRecaptcha) {
      executeRecaptcha().then((captchaToken) => {
        authNewPassword({
          newPassword: values.newPassword,
          email,
          token,
          captchaToken,
        })
          .then((res) => {
            if (res.success) {
              navigate("/login", { replace: true });
              Modal.success({
                title: `${t("login.reset_password")}`,
                content: `${t("login.reset_password_done")}`,
                direction: i18n.dir(),
              });
            } else {
              Modal.error({
                title: `${t("error")}`,
                content: (
                  <ul>
                    {res.error.map((e) => (
                      <li>{t(e)}</li>
                    ))}
                  </ul>
                ),
                direction: i18n.dir(),
              });
            }
          })
          .catch((err) => {
            if (err instanceof Error) {
              message.error(err.message);
            }
          })
          .finally(() => setLoading(false));
      });
    } else {
      Modal.error({
        title: t("error"),
        content: (
          <ul>
            <li>{t("captcha_error")}</li>
          </ul>
        ),
      });
    }
  };

  const colSpan = {
    xs: 22,
    md: 16,
    lg: 16,
    xl: 12,
    xxl: 10,
  };

  return (
    <>
      <ContentHeader title={t("formulaire_reset_password")} />
      <Row justify="center" style={{ flex: 1 }}>
        <Col
          {...colSpan}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Form
            onFinish={onFinish}
            labelCol={{ span: 12 }}
            labelAlign="left"
            form={form}
            validateTrigger="onBlur"
            scrollToFirstError={{
              behavior: "smooth",
            }}
            validateMessages={{
              required: t("messages.champ_obligatoire").toString(),
            }}
          >
            <Form.Item
              name="newPassword"
              label={t("npassword")}
              hasFeedback
              rules={[
                { required: true },
                { message: t("messages.email_invalide").toString() },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    } else {
                      const pattern = new RegExp(
                        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$"
                      );
                      if (pattern.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(t("messages.invalide_pass"));
                      }
                    }
                  },
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              label={t("repeat_npassword")}
              hasFeedback
              dependencies={["newPassword"]}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("messages.not_same_newpass"));
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                {t("login.reset_password")}
              </Button>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            ></div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ResetPasswordPage;
