import { Button, Col, Row, Tag, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BUTTON_STYLE,
  COLOR_PRIMARY2,
  COLOR_SECONDARY,
} from "../../../config/constants";
import scrollToView from "../../../helpers/scrollToView";
import { useAuth } from "../../../auth/authContext";
import { getNavItems } from "../navItems";

const { Text } = Typography;
const styles = {
  btn: {
    border: "none",
    color: COLOR_PRIMARY2,
    boxShadow: "none",
    fontWeight: "bold",
    justifyContent: "center",
    margin: 0,
    cursor: "pointer",
  },
};
const DrawerComponenet = ({ setOpen }: { setOpen(): void }) => {
  const { isAuthenticated, logout, displayName, sessionInfo } = useAuth();

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/", { replace: true });
  };

  const handleLanguage = () => {
    i18n.changeLanguage(i18n.language === "fr" ? "ar" : "fr");
    localStorage.setItem("fnpos_lang", i18n.language === "fr" ? "fr" : "ar");
    setOpen();
  };

  return (
    <Row
      gutter={24}
      justify={"center"}
      style={{ textAlign: "center", lineHeight: 4, padding: 20 }}
    >
      <Col span={24}>
        <Link style={styles.btn} to="/" onClick={setOpen}>
          {t("accueil")}
        </Link>
      </Col>

      <Col span={24}>
        <Link
          to={"/#contactez_nous"}
          style={{ ...styles.btn, backgroundColor: "#ffffff" }}
          onClick={(ev) => {
            scrollToView("contactus");
            setOpen();
          }}
        >
          {t("contactez_nous")}
        </Link>
      </Col>

      {getNavItems("mobile", setOpen, t).map((el: any) => {
        return (
          <Col key={el} span={24}>
            {el.label}
          </Col>
        );
      })}
      <Col span={24}>
        {isAuthenticated ? (
          <Button
            style={BUTTON_STYLE}
            onClick={() => {
              navigate("profile");
              setOpen();
            }}
          >
            {t("profile_utilisateur")}
          </Button>
        ) : sessionInfo.inscriptionOuverte ? (
          <Button
            style={BUTTON_STYLE}
            onClick={() => {
              navigate("inscription");
              setOpen();
            }}
          >
            {t("sinscrire")}
          </Button>
        ) : (
          <></>
        )}
      </Col>
      <Col>
        {!isAuthenticated && (
          <Link to="/login" onClick={setOpen}>
            <Button type="default" style={BUTTON_STYLE}>
              {t("connexion")}
            </Button>
          </Link>
        )}
        {isAuthenticated && (
          <Tag color={COLOR_SECONDARY}>
            {t("navbar.bienvenue")} : {displayName}
            <Button
              style={{
                backgroundColor: COLOR_SECONDARY,
                color: "#fff",
                border: "none",
              }}
              onClick={handleLogout}
            >
              <Text underline style={{ color: "white" }}>
                {t("deconnexion")}
              </Text>
            </Button>
          </Tag>
        )}
      </Col>
      <Col span={24}>
        <Button style={{ fontWeight: "bold" }} onClick={handleLanguage}>
          {i18n.language === "fr" ? "عربي" : "Français"}
        </Button>
      </Col>
    </Row>
  );
};

export default DrawerComponenet;
