import { CSSProperties } from "react";
import { COLOR_PRIMARY2, COLOR_PRIMARY_LIGHT } from "../../config/constants";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    minHeight: "calc(50vh)",
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    gap: 30,
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",
    textAlign: "right",
    lineHeight: 2,
  },
  heroWelcome: {
    margin: 0,
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    textAlign: "right",
    fontWeight: "bold",
  },
};
const RuralConstructionSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <Row style={styles.rowContainer} justify="center">
      <Col
        className={"inscription-pic"}
        style={{ ...styles.secondColContainer }}
      >
        <img
          src={window.location.origin + "/handHouse.png"}
          alt="fnpos"
          width={"400px"}
          height={"400px"}
        />
      </Col>
      <Col style={styles.colContainer} xs={22} lg={14}>
        <Space direction="vertical" size="small">
          <h5
            style={{
              ...styles.heroWelcome,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("rural_construction.title")}{" "}
          </h5>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("rural_construction.body")}
          </p>
        </Space>
      </Col>
    </Row>
  );
};

export default RuralConstructionSection;
