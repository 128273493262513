import { CSSProperties } from "react";
import { ConfigProvider, Input, InputProps } from "antd";
import { useTranslation } from "react-i18next";

const CustomInputNumber = ({ style, ...rest }: InputProps) => {
  const { i18n } = useTranslation();
  const customStyle: CSSProperties = {
    ...style,
    textAlign: i18n.dir() === "rtl" ? "right" : "left",
    direction: "ltr",
  };

  return (
    <ConfigProvider direction="ltr">
      <Input {...rest} style={customStyle} />
    </ConfigProvider>
  );
};

export default CustomInputNumber;
