import { Alert, Button, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2, COLOR_SECONDARY } from "../../config/constants";
import { IProfileData } from "../../types/profile";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/authContext";
import {
  DownloadConvocation,
  DownloadRecuInscription,
} from "../../api/documents";
import { CSSProperties } from "react";

const LeftSide = ({
  profile,
  location,
  handleModifierDemande,
  handleModifierConjoint,
  handleModifierInfomrations,
  loadingDemandeurInfo,
  loadingConjointInfo,
  
}: {
  profile: IProfileData;
  location: string;
  handleModifierDemande:()=>void;
  handleModifierConjoint: ()=>void;
  handleModifierInfomrations: ()=>void;
  loadingDemandeurInfo:boolean;
  loadingConjointInfo: boolean;
  
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { sessionInfo } = useAuth();
  const notesDemaande = {
    key: "noteTotale",
    title: t("notes.note_total"),
    note: profile.currentDemande?.noteTotale,
  };


  const styles: { [key: string]: CSSProperties | undefined } = {
    updateBtn: {
      backgroundColor: "orange",
      color:"white"
    },
    
  }

  const displayName =
    i18n.language === "ar"
      ? `${profile?.nomAr} ${profile?.prenomAr}`
      : `${profile?.nomLt} ${profile?.prenomLt}`;

  const renderStatutDemandeTags = (statutDemande?: number) => {
    switch (statutDemande) {
      case 2:
        return <Tag color="green">{t("statut.eligible")}</Tag>;
      case 3:
        return <Tag color="#D31A18">{t("statut.rejete")}</Tag>;
      case 4:
        return <Tag color={COLOR_SECONDARY}>{t("statut.mise_en_attente")}</Tag>;
      default:
        return <Tag color="#108ee9">{t("statut.encours")}</Tag>;
    }
  };
  const renderStatutRecoursTags = (statutDemande?: number) => {
    switch (statutDemande) {
      case 2:
        return (
          <Tag color={COLOR_SECONDARY}>{t("statut_recours.eligible")}</Tag>
        );
      case 3:
        return <Tag color="#D31A18">{t("statut_recours.rejete")}</Tag>;

      default:
        return <Tag color="#108ee9">{t("statut_recours.encours")}</Tag>;
    }
  };
  const EtatDemand = (statusDemand: number) => {
    return statusDemand !== 3 && statusDemand !== 4;
  };
  const handleLeftSideBackGround = (statusId: number) => {
    switch (statusId) {
      case 1:
        return "info";
      case 2:
        return "success";
      case 3:
        return "error";
      case 4:
        return "success";
      default:
        return "info";
    }
  };

  const handleDownloadInscription = () => {
    DownloadRecuInscription(i18n.dir());
  };
  const handleDownloadConvocation = () => {
    DownloadConvocation(i18n.dir());
  };

  const NoteTotale = ({ profile }: { profile: IProfileData }) => {
    const MotifId12 = profile.currentDemande?.demandeMotifRejets?.find(
      (el) => el.motifRejetId === 12
    );

    return !(profile.currentDemande?.statutDemande === 1) ? (
      profile.currentDemande?.noteTotale === 0 ? (
        MotifId12 !== undefined ? (
          <></>
        ) : (
          <>
            <Typography.Paragraph
              key={notesDemaande.key + "TITLE"}
              style={{
                color: COLOR_PRIMARY2,
                padding: 2,
              }}
            >
              {notesDemaande.title}
            </Typography.Paragraph>
            <Typography.Paragraph
              key={notesDemaande.key + "VALUE"}
              style={{
                background: "#FFF",
                color: COLOR_PRIMARY2,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              {notesDemaande.note || 0} {"  "}
              {t("notes.note")}
            </Typography.Paragraph>
          </>
        )
      ) : (
        <>
          <Typography.Paragraph
            key={notesDemaande.key + "TITLE"}
            style={{
              color: COLOR_PRIMARY2,
              padding: 2,
            }}
          >
            {notesDemaande.title}
          </Typography.Paragraph>
          <Typography.Paragraph
            key={notesDemaande.key + "VALUE"}
            style={{
              background: "#FFF",
              color: COLOR_PRIMARY2,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
            }}
          >
            {notesDemaande.note || 0} {"  "}
            {t("notes.note")}
          </Typography.Paragraph>
        </>
      )
    ) : (
      <></>
    );
  };

  return (
    <>
      <Typography.Title
        level={5}
        style={{
          color: COLOR_PRIMARY2,
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        {displayName}
      </Typography.Title>
      <div>
        <Typography.Paragraph
          style={{
            borderBottom: "1px solid #C1C1C1",
            paddingBottom: 5,
          }}
        >
          <strong>{t("profile.num_inscription")} :</strong>{" "}
          {profile?.currentDemande?.numInscription}
        </Typography.Paragraph>
        <Typography.Paragraph
          style={{
            borderBottom: "1px solid #C1C1C1",
            paddingBottom: 5,
          }}
        >
          <strong>{t("profile.date_inscription")} :</strong>{" "}
          {dayjs(profile?.currentDemande?.dateDemande).format("DD-MM-YYYY")}
        </Typography.Paragraph>

        <Typography.Paragraph
          style={{
            borderBottom: "1px solid #C1C1C1",
            paddingBottom: 5,
          }}
        >
          {profile.currentDemande?.recoursExist ? (
            <>
              <strong>{t("profile.statut_recours")} : </strong>
              {renderStatutRecoursTags(profile?.recours?.statutRecours)}
            </>
          ) : (
            <>
              <strong>{t("profile.statut_demande")} : </strong>
              {renderStatutDemandeTags(profile?.currentDemande?.statutDemande)}
            </>
          )}

          {!(profile.currentDemande?.statutDemande !== 3) &&
          !profile.currentDemande?.recoursExist ? (
            location === "/profile" || location === "/profile/" ? (
              sessionInfo.recoursOuvert ? (
                <Tag
                  style={{ cursor: "pointer" }}
                  color={COLOR_PRIMARY2}
                  onClick={() => navigate("/recours")}
                >
                  {t("demande_recours")}
                </Tag>
              ) : (
                <></>
              )
            ) : (
              <Tag
                style={{ cursor: "pointer" }}
                color={COLOR_PRIMARY2}
                onClick={() => navigate("/profile")}
              >
                {t("espace_demandeur")}
              </Tag>
            )
          ) : (
            <></>
          )}
        </Typography.Paragraph>
        {profile?.currentDemande?.recoursExist ? (
          <Typography.Paragraph
            style={{
              borderBottom: "1px solid #C1C1C1",
              paddingBottom: 5,
            }}
          >
            <strong>{t("profile.date_recours")} :</strong>{" "}
            {dayjs(profile?.recours?.dateCreation).format("DD-MM-YYYY")}
          </Typography.Paragraph>
        ) : (
          <></>
        )}

        {(profile?.currentDemande?.recoursExist &&
          profile?.recours?.statutRecours === 2) ||
        profile?.currentDemande?.statutDemande === 2 ? (
          <>
            <Typography.Paragraph
              style={{
                borderBottom: "1px solid #C1C1C1",
                paddingBottom: 5,
              }}
            >
              <strong>{t("profile.date_rdv")} :</strong>{" "}
              {dayjs(profile?.currentDemande.dateRDV).format("DD-MM-YYYY")}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                borderBottom: "1px solid #C1C1C1",
                paddingBottom: 5,
              }}
            >
              <strong>{t("profile.plage")} :</strong>{" "}
              {profile?.currentDemande.plageHeure}
            </Typography.Paragraph>
          </>
        ) : (
          <></>
        )}
       
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
        }}
      >
       

        <NoteTotale profile={profile} />
      </div>
      {profile.currentDemande?.recoursExist ? (
        <Alert
          type={handleLeftSideBackGround(
            Number(profile.recours?.statutRecours)
          )}
          message={
            profile.recours?.recoursMotifRejets?.length! > 1 ? (
              <ul>
                {i18n.language === "ar"
                  ? profile?.recours?.recoursMotifRejets?.map((m) => {
                      return <li>{m.motifRejetAr}</li>;
                    })
                  : profile?.recours?.recoursMotifRejets?.map((m) => {
                      return <li>{m.motifRejetLt} </li>;
                    })}
              </ul>
            ) : i18n.language === "ar" ? (
              profile?.recours?.recoursMotifRejets?.map(
                (m) => m.motifRejetAr
              )
            ) : (
              profile?.recours?.recoursMotifRejets?.map(
                (m) => m.motifRejetLt
              )
            )
          }
        />
      ) : (
        <Alert
          type={handleLeftSideBackGround(
            Number(profile.currentDemande?.statutDemande)
          )}
          message={
            profile.currentDemande?.demandeMotifRejets?.length! > 1 ? (
              <ul>
                {i18n.language === "ar"
                  ? profile?.currentDemande?.demandeMotifRejets?.map((m) => {
                      return <li>{m.motifRejetAr}</li>;
                    })
                  : profile?.currentDemande?.demandeMotifRejets?.map((m) => {
                      return <li>{m.motifRejetLt} </li>;
                    })}
              </ul>
            ) : i18n.language === "ar" ? (
              profile?.currentDemande?.demandeMotifRejets?.map(
                (m) => m.motifRejetAr
              )
            ) : (
              profile?.currentDemande?.demandeMotifRejets?.map(
                (m) => m.motifRejetLt
              )
            )
          }
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 15,
          marginTop: 10,
        }}
      >
        {profile.currentDemande?.statutDemande === 2 ? (
          <Button type="primary" onClick={handleDownloadConvocation}>
            {t("telecharger_convocation")}
          </Button>
        ) : profile.recours?.statutRecours === 2 ? (
          <Button type="primary" onClick={handleDownloadConvocation}>
            {t("telecharger_convocation")}
          </Button>
        ) : (
          <></>
        )}
       
        <Button type="primary" onClick={handleDownloadInscription}>
          {t("telecharger_formulaire")}
        </Button>
      </div>
      
      <div style={{marginTop:50, padding:10,display:"flex",flexDirection:"column",gap:20}}>
        
      {sessionInfo.modificationOuvert? (<><Button loading={loadingDemandeurInfo} onClick={handleModifierInfomrations} type="primary" style={styles.updateBtn}>
          {t('btn_edit_demandeur')}
        </Button>
        
        
        
     <Button loading={loadingConjointInfo} onClick={handleModifierConjoint} type="primary" style={styles.updateBtn}>
          {profile.conjointExiste?t('btn_edit_conjoint'):t('btn_create_conjoint')}
        </Button>
        
        
        
     <Button onClick={handleModifierDemande} type="primary" style={styles.updateBtn} >
     {t('btn_edit_demande')}
        </Button> </>):null}
        


      </div>
     

    </>
  );
};

export default LeftSide;
