import { Button, Result, Typography } from "antd";
import AnimatedSuccess from "./loading-success-failed.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { confirmChangeEmail } from "../../api/auth";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/ContentHeader";

const LottieLoading = () => (
  <Lottie
    animationData={AnimatedSuccess}
    autoPlay={true}
    loop={true}
    initialSegment={[0, 238]}
    style={{ width: 250 }}
  />
);
const LottieSuccess = () => (
  <Lottie
    animationData={AnimatedSuccess}
    autoPlay={true}
    loop={false}
    initialSegment={[239, 403]}
    style={{ width: 250 }}
  />
);
const LottieFail = () => (
  <Lottie
    animationData={AnimatedSuccess}
    autoPlay={true}
    loop={false}
    initialSegment={[658, 840]}
    style={{ width: 250 }}
  />
);

const ConfirmChangeEmailPage = () => {
  const [state, setState] = useState<"loading" | "success" | "fail">("loading");
  const [loop, setLoop] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!params.get("email") || !params.get("token")) {
      navigate("/404");
    }
  });

  const title =
    state == "loading" ? (
      <Typography.Title>
        {t("inscription.confirmation_email.loading")}
      </Typography.Title>
    ) : state == "success" ? (
      <Typography.Title type="success">
        {t("inscription.confirmation_email.success")}
      </Typography.Title>
    ) : (
      <Typography.Title type="danger">
        {t("inscription.confirmation_email.error")}
      </Typography.Title>
    );

  useEffect(() => {
    let email = params.get("email") || "";
    let token = params.get("token") || "";

    const data = { email, token };

    lottieRef.current?.playSegments([0, 238], true);
    confirmChangeEmail(data).then((res) => {
      console.log(res);
      if (res) {
        lottieRef.current?.playSegments([239, 403], true);
        setState("success");
      } else {
        lottieRef.current?.playSegments([658, 820], true);
        setState("fail");
        setMessage(t(res.errors &&res.errors[0]) || "");
      }
    });
  }, []);

  return (
    <>
      <ContentHeader title={t("formulaire_activation_compte")} />
      <Result
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
        status="success"
        icon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie
              lottieRef={lottieRef}
              animationData={AnimatedSuccess}
              loop={loop}
              style={{
                width: 250,
              }}
            />
          </div>
        }
        title={title}
        subTitle={message}
        extra={
          state == "loading"
            ? []
            : [
                <Link to="/login">
                  <Button type="primary" size="middle">
                    {t("login.login")}
                  </Button>
                </Link>,
                <Link to="/">
                  <Button size="middle">{t("accueil")}</Button>
                </Link>,
              ]
        }
      />
    </>
  );
};

export default ConfirmChangeEmailPage;
