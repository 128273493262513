import { Col, Collapse, Row } from "antd";
import { CSSProperties } from "react";

import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2, COLOR_PRIMARY_LIGHT } from "../../config/constants";
const { Panel } = Collapse;

const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    padding: 5,
    minHeight: "calc(50vh)",
    alignItems: "center",
    margin: 0,
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: 20,
    gap: 30,
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",
  },
  heroWelcome: {
    margin: 20,
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  collapse: {
    backgroundColor: "#f2f2f2",
    fontSize: 21,
  },
  collapseHeader: { fontWeight: "bold" },
  desc: {
    fontWeight: "bold",
    marginRight: 5,
    fontSize: 18,
  },

};

const ProcedureSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Row
        gutter={24}
        style={styles.rowContainer}
        justify={"center"}
        id="demarche_suivie"
      >
        <Col xs={24} lg={20}>
          <h5
            style={{
              ...styles.heroWelcome,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("procedures_section.title")}{" "}
          </h5>

          <Collapse style={styles.collapse} accordion>
            <Panel
              className={"panel1"}
              style={styles.collapseHeader}
              header={
                t("etape") + " 1: " + t("procedures_section.body.step1.info")
              }
              key="1"
            >
              <div>
                <ul>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {t("procedures_section.body.step1.steps.stepOne.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step1.steps.stepOne.desc")}{" "}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      <span
                        style={{ fontWeight: "bold", margin: 0, color: "red" }}
                      >
                        {t("observation") + " : "}
                      </span>{" "}
                      {t(
                        "procedures_section.body.step1.steps.stepOne.observ.body"
                      )}
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {t("procedures_section.body.step1.steps.stepTwo.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step1.steps.stepTwo.desc")}
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {t("procedures_section.body.step1.steps.stepThree.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step1.steps.stepThree.desc")}
			< br />
			{t("inscription.confirmation.confirmation_message")}
                    </p>

                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      <span
                        style={{ fontWeight: "bold", margin: 0, color: "red" }}
                      >
                        {t("observation") + " : "}
                      </span>
                      {t(
                        "procedures_section.body.step1.steps.stepThree.observ.body"
                      )}
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {t("procedures_section.body.step1.steps.stepFour.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step1.steps.stepFour.desc")}
                    </p>
                  </li>
                </ul>
              </div>
            </Panel>
            <Panel
              className={"panel2"}
              style={styles.collapseHeader}
              header={
                t("etape") + " 2: " + t("procedures_section.body.step2.info")
              }
              key="2"
            >
              <div>
                <p
                  style={{
                    ...styles.desc,
                    fontWeight: "normal",
                    textAlign: i18n.language === "fr" ? "left" : "right",
                  }}
                >
                  {t("procedures_section.body.step2.body")}
                </p>
              </div>
            </Panel>
            <Panel
              className={"panel3"}
              style={styles.collapseHeader}
              header={
                t("etape") + " 3: " + t("procedures_section.body.step3.info")
              }
              key="3"
            >
              <p style={{ fontWeight: "normal", fontSize: 17 }}>
                {t("procedures_section.body.step3.body")}
              </p>
              <div>
                <ul>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {"1- " +
                        t("procedures_section.body.step3.steps.stepOne.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step3.steps.stepOne.desc")}
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {"2- " +
                        t("procedures_section.body.step3.steps.stepTwo.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step3.steps.stepTwo.desc")}
                    </p>

                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      <span
                        style={{ fontWeight: "bold", margin: 0, color: "red" }}
                      >
                        {t("observation") + " : "}
                      </span>
                      {t(
                        "procedures_section.body.step3.steps.stepTwo.observ.body"
                      )}
                    </p>
                  </li>
                  {/* <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {"3- " +
                        t(
                          "procedures_section.body.step3.steps.stepThree.title"
                        )}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step3.steps.stepThree.desc1")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step3.steps.stepThree.desc2")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step3.steps.stepThree.desc3")}
                    </p>
                  </li> */}
                </ul>
              </div>
            </Panel>
            <Panel
              className={"panel4"}
              style={styles.collapseHeader}
              header={
                t("etape") + " 4: " + t("procedures_section.body.step4.info")
              }
              key="4"
            >
              <p style={{ fontWeight: "normal", fontSize: 17 }}>
                {t("procedures_section.body.step4.body")}
              </p>
              <div>
                <ul>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {"1- " +
                        t("procedures_section.body.step4.steps.stepOne.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step4.steps.stepOne.body")}
                    </p>
                  </li>
                  <li>
                    <p style={{ fontWeight: "bold", fontSize: 17 }}>
                      {"2- " +
                        t("procedures_section.body.step4.steps.stepTwo.title")}
                    </p>
                    <p style={{ fontWeight: "normal", fontSize: 14 }}>
                      {t("procedures_section.body.step4.steps.stepTwo.body")}
                    </p>
                  </li>
                </ul>
              </div>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default ProcedureSection;
