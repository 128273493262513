import { createBrowserRouter } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import { ChangeEmailPage, ConfirmChangeEmail, HomePage, LoginPage, RegisterPage, RensendConfirmationAccount } from "../pages";
import ConfirmEmailPage from "../pages/ConfirmEmailPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import ProfilePage from "../pages/ProfilePage";
import StatusPage from "../pages/StatusPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import {
  InscriptionNonAutorisee,
  RecoursNonAutorisee,
} from "../pages/NonAutorisee";
import {
  AuthRoute,
  PrivateRoute,
  SessionInscriptionAutoriseeRoute,
  SessionInscriptionRoute,
  SessionRecoursAutoriseeRoute,
  SessionRecoursRoute,
} from "./RouteTypes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: (
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        ),
      },
      {
        path: "/inscription",
        element: (
          <SessionInscriptionRoute>
            <AuthRoute>
              <RegisterPage />
            </AuthRoute>
          </SessionInscriptionRoute>
        ),
      },
      {
        path: "/confirm-email",
        element: (
          <SessionInscriptionRoute>
          <AuthRoute>
            <ConfirmEmailPage />
          </AuthRoute>
          </SessionInscriptionRoute>
        ),
      },
      {
        path: "/change-email",
        element: (
          <SessionInscriptionRoute>
          <AuthRoute>
            <ConfirmChangeEmail />
          </AuthRoute>
          </SessionInscriptionRoute>
        ),
      },
      {
        path: "/reset-email",
        element: (
          <SessionInscriptionRoute>
          <AuthRoute>
            <ChangeEmailPage />
          </AuthRoute>
          </SessionInscriptionRoute>
        ),
      },
      {
        path: "/forget-password",
        element: (
          <AuthRoute>
            <ForgetPasswordPage />
          </AuthRoute>
        ),
      },
      {
        path: "/resend-confirmation-account",
        element: (
          <SessionInscriptionRoute>
          <AuthRoute>
            <RensendConfirmationAccount />
          </AuthRoute>
          </SessionInscriptionRoute>
        ),
      },
      {
        path: "/reset-password",
        element: (
          <AuthRoute>
            <ResetPasswordPage />
          </AuthRoute>
        ),
      },
      {
        path: "/404",
        element: <StatusPage type="errorCode" statusCode="404" />,
      },
      {
        path: "/profile",
        children: [
          {
            path: "/profile",
            element: (
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: "/recours",
        element: (
          <SessionRecoursRoute>
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          </SessionRecoursRoute>
        ),
      },
      {
        path: "/inscriptionnonautorisee",
        element: (
          <SessionInscriptionAutoriseeRoute>
            <InscriptionNonAutorisee />,
          </SessionInscriptionAutoriseeRoute>
        ),
      },
      {
        path: "/recoursnonautorisee",

        element: (
          <SessionRecoursAutoriseeRoute>
            <RecoursNonAutorisee />,
          </SessionRecoursAutoriseeRoute>
        ),
      },
    ],
  },
]);

export default router;
