import {Col, Layout, Row} from "antd";
import {useTranslation} from "react-i18next";
import {CSSProperties} from "react";
import FacebookFilled from './../../assets/facebook.svg';
import TwitterOutlined from './../../assets/twitter.svg';
import {Space} from "antd/lib";

const styles: { [key: string]: CSSProperties | undefined } = {
    footer: {
        display:"flex",
        textAlign:'center' ,
        boxShadow: '#d7d7d7 0px -2px 16px 0px',
        flexDirection:"column",
        justifyContent:"center",
    }


}
const Footer = () => {
const {t} = useTranslation();

    return (
        <Layout.Footer style={styles.footer}>


            <Row justify={'space-around'} style={{alignItems:"center"}}>

                            <Col xs={24} lg={4}>

                                   <Row style={{flexDirection:"column", alignItems:'center'}}>
                                       <Col xs={24} lg={12}>
                                           <p>{t('contactez_nous')}</p>
                                       </Col>
                                       <Col xs={24} lg={12}>
                                           <a href = "mailto: contact@fnpos.dz">contact@fnpos.dz</a>
                                       </Col>
                                   </Row>


                            </Col>
                        <Col xs={24} lg={16}>
                            <p> {t('fnpos')+ " - "+ t('footer.droit_reserve') } &copy; 2023</p>
                        </Col>
                            <Col xs={24} lg={4}>
                            <Row style={{flexDirection:"column", alignItems:'center'}}>
                                <Col xs={12} lg={14}>
                                    <p>{t('suivez_nous_sites')}</p>
                                </Col>
                                <Col xs={12} lg={6}>
                                        <Space>
                                            <a href={"https://www.facebook.com/fnposalgerie"} target="_blank" rel="noreferrer">
                                                <img src={FacebookFilled} width={33} height={33} alt="fnpos-facebook" />

                                            </a>
                                            <a href={"https://twitter.com/fnpos_officiel"} target="_blank" rel="noreferrer">
                                                <img src={TwitterOutlined} width={33} height={33} alt="fnpos-twitter" />

                                            </a>
                                        </Space>
                                </Col>
                            </Row>
                            </Col>
            </Row>



    </Layout.Footer>
    )
}

export  default Footer;