import { CSSProperties } from "react";
import { Button, Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2 } from "../../config/constants";
import { Link } from "react-router-dom";
import { useAuth } from "../../auth/authContext";

const styles: { [key: string]: CSSProperties | undefined } = {
  rowContainer: {
    minHeight: "calc(50vh)",
  },
  colContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: 20,
    gap: 30,
  },
  secondColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 30,
  },
  heroText: {
    margin: 0,
    color: "black",
    fontSize: "1.1rem",

    lineHeight: 2,
  },
  heroWelcome: {
    margin: 0,
    color: COLOR_PRIMARY2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  btnSinscrire: {
    borderRadius: "50px",
    backgroundColor: COLOR_PRIMARY2,
    color: "white",
    paddingInline: 30,
    marginLeft: 50,
  },
};
const AboutSection = () => {
  const { t, i18n } = useTranslation();

  const { isAuthenticated, sessionInfo } = useAuth();

  return (
    <Row id="a_propos_du_service" style={styles.rowContainer} justify="center">
      <Col style={styles.colContainer} xs={22} lg={14}>
        <Space direction="vertical" size="small">
          <h5
            style={{
              ...styles.heroWelcome,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("a_propos_service.title")}
          </h5>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("a_propos_service.body1")}
          </p>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("a_propos_service.body2")}
          </p>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("a_propos_service.body3")}
          </p>
          <p
            style={{
              ...styles.heroText,
              textAlign: i18n.language === "fr" ? "left" : "right",
            }}
          >
            {t("a_propos_service.body4")}
          </p>
        </Space>
        {!isAuthenticated && sessionInfo.inscriptionOuverte ? (
          <Link to={"/inscription"}>
            <Button style={styles.btnSinscrire}>{t("sinscrire")}</Button>
          </Link>
        ) : (
          <></>
        )}
      </Col>
      <Col
        className={"inscription-pic"}
        style={{ ...styles.secondColContainer }}
      >
        <img
          src={window.location.origin + "/hawla-khidma.png"}
          alt="fnpos"
          width={"400px"}
          height={"220px"}
        />
      </Col>
    </Row>
  );
};

export default AboutSection;
