import { Button, Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ILoginCredentials } from "../../types/types";
import { useAuth } from "../../auth/authContext";
import { Link } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader";

const LoginPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { login, loading,sessionInfo } = useAuth();
  
  const onFinish = (values: ILoginCredentials) => {
    login(values);
  };

  const colSpan = {
    xs: 20,
    md: 16,
    lg: 14,
    xl: 9,
    xxl: 8,
  };

  return (
    <>
      <ContentHeader title={t("formulaire_connexion")} />
      <Row justify="center" style={{ flex: 1 }}>
        <Col
          {...colSpan}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Form
            onFinish={onFinish}
            labelCol={{ span: 9 }}
            labelAlign="left"
            form={form}
            validateTrigger="onBlur"
            scrollToFirstError={{
              behavior: "smooth",
            }}
            validateMessages={{
              required: t("messages.champ_obligatoire").toString(),
            }}
          >
            <Form.Item
              name="numInscription"
              label={t("login.numInscription")}
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("login.password")}
              rules={[{ required: true }]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("login.login")}
              </Button>
            </Form.Item>
            <div
              style={{
                display: "flex",
                gap: "10px",

                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Link to="/forget-password">
                <Button
                  style={{
                    padding: 0,
                  }}
                  type="link"
                >
                  {t("login.mot_de_passe_oublie")}
                </Button>
              </Link>

             { sessionInfo.inscriptionOuverte ? <Link to="/resend-confirmation-account">
                <Button
                  style={{
                    padding: 0,
                    // fontWeight: "bold",
                    // color: "red",
                  }}
                  type="link"
                >
                  {t("login.non_confirmer")}
                </Button>
                </Link>:null}
             {sessionInfo.inscriptionOuverte? <Link to="/reset-email">
                <Button
                  style={{
                    padding: 0,
                    fontWeight: "bold",
                    color: "red",
                  }}
                  type="link"
                >
                  {t("formulaire_change_email")}
                </Button>
              </Link>:null}

          {sessionInfo.inscriptionOuverte? <Link to="/inscription">
                <Button
                  style={{
                    padding: 0,
                  }}
                  type="link"
                >
                  {t("login.non_inscrit")}
                </Button>
              </Link>: null}
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
