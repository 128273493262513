import { DatePicker, Divider, Form, FormInstance, Input, InputNumber, Modal, Radio, Select,  Typography, message } from "antd";
import dayjs from "dayjs";
import { FocusEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommune, ICreateConjiont, IUpdateConjoint, IWilaya } from "../../types/types";
import {  arabicInputNormalize, getCleASS, latinInputNormalize, nActNormalize, ninNormalize, nssNormalize } from "../../helpers/validators";

import { situation_professionnelle_conjoint } from "../../data/data";
import { getCommunes } from "../../api/wilayas";
import CustomInputNumber from "../../components/CustomInputNumber";
import { CreateConjoint, UpdateConjointInfo } from "../../api/demande";


export interface IUpdateDemande {
  form: FormInstance;
  wilayas?: IWilaya[];
  isVisible:boolean;
  setIsVisible: (arg0:boolean)=>void;
  onWilayaChange:any;
  communes:ICommune[];
  isUpdated:boolean;
  setIsUpdated: (arg0:boolean)=>void;
  conjointExist: boolean;
  situationProfessionnel?: number;
  

  }
  

const UpdateConjoint = ({wilayas,isVisible,form,setIsVisible,onWilayaChange,communes,isUpdated,setIsUpdated,conjointExist,situationProfessionnel}:IUpdateDemande) => {
  const { t, i18n } = useTranslation();

  const [isUpdateConjointLoading, setIsUpdateConjointLoading] = useState(false);

  const [loadingCommunes] = useState(false);

  const [conjointSitutationProfessionnel, setConjointSitutationProfessionnel] = useState(situationProfessionnel);


  const nssPattern = () => {
    let nss = form.getFieldValue(["cojoint", "numSecuriteSociale"]) || "";
    let dateNaissance = form.getFieldValue(["cojoint", "dateNaissance"]) || "";
    if (`${nss}`.length === 12 && dateNaissance) {
      let year = dateNaissance.format("YY");
      let key = getCleASS(nss);
      let nss2 = `${nss}`.slice(2, 10);
      let pattern = `^${year}${nss2}${key}$`;
      return new RegExp(pattern);
    }
    return new RegExp(`^[0-9]{12}$`);
  };
    const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
      let date = e.target.value;
      if (date) {
        let date1 = dayjs(date, "YYYY-MM-DD");
        let date2 = dayjs(date, "DD-MM-YYYY");
        let date3 = dayjs(date, "DD/MM/YYYY");
        let date4 = dayjs(date, "YYYY/MM/DD");
        let res = date1.isValid()
          ? date1
          : date2.isValid()
          ? date2
          : date3.isValid()
          ? date3
          : date4.isValid()
          ? date4
          : null;
        if (res) {
          form.setFieldsValue({
            cojoint: {
              dateNaissance: res,
            },
          });
        }
      }
      form.validateFields([["cojoint", "dateNaissance"]]);
      form.validateFields([["cojoint", "numSecuriteSociale"]]);
    };

  
    const formatterNumber = (val: any) => {
      if (!val) return "0";
      return `${val}`.replace(",", "");
    };
  
    const parserNumber = (val: any) => {
      if (!val) return "0";
      return Number.parseFloat(val.replace(",", ".")).toFixed(2);
    };
interface IFormConjoint {
  cojoint: ICreateConjiont;
}
 const onFinish = ({cojoint}: IFormConjoint) => {
     setIsUpdateConjointLoading(true);
    
     if(!conjointExist) {
        CreateConjoint(cojoint).then((res)=> {

          if(res.status===200) {
            setIsUpdated(!isUpdated);
            Modal.success({
              title: `${t("popup_title_add_conjoint_success")}`,
              content: `${t("popup_message_add_conjoint_success")}`,
              direction: i18n.dir(),
            });
            setIsVisible(false);
          form.resetFields();
          }else {
            let resp = res.response.data;
            if(typeof(resp)!=="string") {              
              Modal.error({
                title: t("popup_title_add_conjoint_error"),
                content: (
                  <ul>
                    {Object.keys(resp.errors).map((e:any, i:number) => (
                      <li key={i}>{t(resp.errors[e])}</li>
                    ))}
                  </ul>
                ),
                direction: i18n.dir(),
              });
            }else {
              
              Modal.error({
                title: t("popup_title_add_conjoint_error"),
                content: (
                  <ul>
                 
                      <li >{t(resp)}</li>
                 
                  </ul>
                ),
                direction: i18n.dir(),
              });
              
            }
          }
        }).catch((err:Error)=> {
          Modal.error({
            title: t("popup_title_add_conjoint_error"),
            content: (
              <ul>
                <li>{t("popup_message_add_conjoint_error")}</li>
              </ul>
            ),
          });
        }).finally(()=>{
          setIsUpdateConjointLoading(false);
        });
        
     }else {
      
      UpdateConjointInfo(cojoint).then(res=> {
        if(res.status===200) { 
          setIsUpdated(!isUpdated);
          Modal.success({
            title: `${t("popup_title_success")}`,
            content: `${t("popup_message_success_conjoint")}`,
            direction: i18n.dir(),
          });
          setIsVisible(false);
        form.resetFields();
        }else {
          let resp = res.response.data;
          if(typeof(resp)!=="string") {              
            Modal.error({
              title: t("popup_title_error"),
              content: (
                <ul>
                  {Object.keys(resp.errors).map((e:any, i:number) => (
                    <li key={i}>{t(resp.errors[e])}</li>
                  ))}
                </ul>
              ),
              direction: i18n.dir(),
            });
          }else {
            
            Modal.error({
              title: t("popup_title_error"),
              content: (
                <ul>
               
                    <li >{t(resp)}</li>
               
                </ul>
              ),
              direction: i18n.dir(),
            });
            
          }
        }
      }).catch(err=> {
        Modal.error({
          title: t("popup_title_error"),
          content: (
            <ul>
              <li>{t("popup_message_error_conjoint")}</li>
            </ul>
          ),
        });
      }).finally(()=>{
        setIsUpdateConjointLoading(false);
      })
     }
    
 }

    return (
        <Modal title={  
            <>
            <Typography.Title style={{ margin: 0 }} level={4}>
             {conjointExist?t('modal_edit_conjoint'):t('modal_create_conjoint')}
            </Typography.Title>
            <Divider />
          </>} 
          width={700}
          open={isVisible}
          onCancel={()=>{
            setIsVisible(false);
            form.resetFields();
          }}
          okText={t('modal_btn_save')}
            onOk={()=>form.submit()} cancelButtonProps={{disabled: isUpdateConjointLoading}} okButtonProps={{ loading:isUpdateConjointLoading}}>
            
            <Form   
                onFinish={onFinish}
                labelCol={{ span: 9 }}
                labelAlign="left"
                form={form}
                size="large"
                validateTrigger="onBlur"
                validateMessages={{
                  required: t("messages.champ_obligatoire").toString(),
                }}>

     
     <Form.Item name={["cojoint", "demandeurId"]} hidden />
     <Form.Item name={["cojoint", "id"]} hidden />
        
     <Form.Item
        name={["cojoint", "nomAr"]}
        label={t("inscription.nom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "nomLt"]}
        rules={[{ required: true }]}
        label={t("inscription.nom_latin")}
        normalize={latinInputNormalize}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomAr"]}
        label={t("inscription.prenom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomLt"]}
        label={t("inscription.prenom_latin")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <div style={{ border: "2px solid #ddd", padding: 10, marginBottom: 20 }}>
        <p
          style={{
            width: i18n.language === "ar" ? "90px" : "140px",
            marginTop: "-24px",
            marginLeft: "5px",
            background: "white",
          }}
        >
          {t("inscription.date_naissance")}
        </p>
        <Form.Item
          name={["cojoint", "presumeConjoint"]}
          label={t("inscription.presume")}
          initialValue={false}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={false}>{t("inscription.non")}</Radio>
            <Radio value={true}>{t("inscription.oui")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={["cojoint", "dateNaissance"]}
          label={t("inscription.date_naissance")}
          rules={[
            { required: true },
            {
              validator(_, value) {
                const presume = form.getFieldValue([
                  "cojoint",
                  "presumeConjoint",
                ]);
                if (!value) {
                  return Promise.resolve();
                }
                if (presume) {
                  if (value.month() !== 0 || value.date() !== 1) {
                    return Promise.reject(
                      new Error(t("messages.presume_invalide").toString())
                    );
                  }
                }
                if (value.isAfter(dayjs().subtract(16, "year"))) {
                  
                  return Promise.reject(
                    new Error(t("messages.date_invalide").toString())
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            onBlur={onBlurDateNaissance}
            style={{ width: "100%" }}
            defaultPickerValue={dayjs().subtract(16, "year")}
            placeholder=""
            disabledDate={(date) => date.isAfter(dayjs().subtract(16, "year"))}
          />
        </Form.Item>
      </div>

      <Form.Item
        name={["cojoint", "wilayaNaissanceId"]}
        label={t("inscription.wilaya_naissance")}
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["cojoint", "communeNaissanceId"]}
        label={t("inscription.commune_naissance")}
        dependencies={[["cojoint", "wilayaNaissanceId"]]}
        rules={[{ required: true }]}
      >
        <Select loading={loadingCommunes}>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["cojoint", "numActeNaissance"]}
        label={t("inscription.n_acte_naissance")}
        normalize={nActNormalize}
        rules={
          [
            // { required: true }
          ]
        }
      >
        <CustomInputNumber />
      </Form.Item>

      <Form.Item
        name={["cojoint", "numIdentificationNational"]}
        label={t("inscription.nin")}
        normalize={ninNormalize}
        rules={[
          // { required: true },
          { max: 18, message: t("messages.nin_invalide").toString() },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomPereAr"]}
        label={t("inscription.prenom_pere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomPereLt"]}
        label={t("inscription.prenom_pere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cojoint", "nomMereAr"]}
        label={t("inscription.nom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "nomMereLt"]}
        label={t("inscription.nom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomMereAr"]}
        label={t("inscription.prenom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomMereLt"]}
        label={t("inscription.prenom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "situationProfessionnelle"]}
        label={t("inscription.situation_professionnelle")}
        rules={[{ required: true }]}
      >
        <Select
          onChange={(ev) => {
            setConjointSitutationProfessionnel(ev);

          }}
        >
          {situation_professionnelle_conjoint.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return (
            prevValues.cojoint?.situationProfessionnelle !==
            currentValues.cojoint?.situationProfessionelle
          );
        }}
      >
                {({ getFieldValue }) =>
                getFieldValue(['cojoint','situationProfessionnelle']) === 1 ?
                (<><Form.Item
                  name={["cojoint", "revenuMensuel"]}
                  label={t("inscription.revenu_mensuel")}
                  rules={[
                    { required: true },
                    {
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        let floatVal = parseFloat(value);
                        if (floatVal >= 0 && floatVal <= 999999) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            t("messages.revenu_mensuel_invalide").toString()
                          )
                        );
                      },
                    },
                  ]}
                >
                  <InputNumber<string>
                    style={{ width: "70%" }}
                    min="0.00"
                    stringMode
                    keyboard={false}
                    controls={false}
                    step="0.01"
                    formatter={formatterNumber}
                    parser={parserNumber}
                    addonAfter={t("inscription.dinars")}
                  />
                </Form.Item>
                <Form.Item
                  name={["cojoint", "numSecuriteSociale"]}
                  label={t("inscription.nss")}
                  normalize={nssNormalize}
                  dependencies={[["cojoint", "dateNaissance"]]}
                  rules={[
                    { required: true },
                    {
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        let pattern = nssPattern();
                        let correct = pattern.test(value);
                        if (correct) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("messages.nss_invalide").toString())
                        );
                      },
                    },
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item></>):getFieldValue(['cojoint','situationProfessionnelle']) ===3 ?
                 (
                    <Form.Item
                    name={["cojoint", "revenuMensuel"]}
                    label={t("inscription.revenu_mensuel")}
                    rules={[
                      { required: true },
                      {
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          let floatVal = parseFloat(value);
                          if (floatVal >= 0 && floatVal <= 999999) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              t("messages.revenu_mensuel_invalide").toString()
                            )
                          );
                        },
                      },
                    ]}
                  >
                    <InputNumber<string>
                      style={{ width: "70%" }}
                      min="0.00"
                      stringMode
                      keyboard={false}
                      controls={false}
                      step="0.01"
                      formatter={formatterNumber}
                      parser={parserNumber}
                      addonAfter={t("inscription.dinars")}
                    />
                  </Form.Item>
                ):null
                }

      </Form.Item>
            </Form>
        </Modal>
    );
}


export default UpdateConjoint;
