import { ICommune, IWilaya } from "../types/types";
import { API } from "./instance";

export const getWilayas = (captchaToken: string) =>
  API.get<IWilaya[]>("/wilayas/get", { params: { captchaToken } })
    .then((res) => res.data)
    .catch((err) => {
      return [];
    });
export const getAllWilayas = () =>
  API.get<IWilaya[]>("/wilayas/getall")
    .then((res) => res.data)
    .catch((err) => {
      return [];
    });

export const getCommunes = (id: number) =>
  API.get<ICommune[]>(`/communes/get`, { params: { WilayaId: id } })
    .then((res) => res.data)
    .catch((err) => {
      return [];
    });
