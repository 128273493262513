import { API } from "./instance";
import { getDataFromResponse, getErrorsFromResponse } from "./utils";
import { IRecoursForm } from "../types/recours";

const CreateRecours = (data: IRecoursForm) => {
  return API.post<string>("recours/create", { ...data })
    .then((data) => getDataFromResponse(data))
    .catch((err) => getErrorsFromResponse(err));
};

export { CreateRecours };
