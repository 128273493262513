import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import { RegisterStepProps } from ".";
import CustomInputNumber from "../../components/CustomInputNumber";
import {
  sexe,
  situation_familiale,
  situation_professionnelle,
} from "../../data/data";
import {
  arabicInputNormalize,
  latinInputNormalize,
  nActNormalize,
  ninNormalize,
  phoneNormalize,
} from "../../helpers/validators";
import { ICommune } from "../../types/types";
import { useState } from "react";
import { getCommunes } from "../../api/wilayas";

import i18n from "../../config/i18n";
import { checkNewEmail } from "../../api/demande";
import InfoCircleOutlined from './../../assets/infocircle.svg';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const yearsExp = Array.from({ length: 46 }, (_, k) => k);
const monthsExp = Array.from({ length: 12 }, (_, k) => k);
const nbChilds = Array.from({ length: 21 }, (_, k) => k);

const styles = {
  helpImg: {
    height: "500px",
    backgroundImage: "url(./help.jpg)",
    backgroundSize: "95%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};
const InformationsStep = ({ form, current, wilayas }: RegisterStepProps) => {
  const { t } = useTranslation();
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [showModal, setShowModal] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [nbrEnfantsInf30, setNbrEnfantsInf30] = useState<number[]>([0]);

  const onWilayaChange = (value: number) => {
    form.setFieldsValue({ demandeur: { communeNaissanceId: undefined } });
    return getCommunes(value).then((res) => setCommunes(res));
  };

  const handleHelp = () => {
    setShowModal(true);
  };
  function handleChangeNbrEnfants(value: number) {
    setNbrEnfantsInf30(Array.from({ length: value + 1 }, (_, k) => k));
  }
  const formatterNumber = (val: any) => {
    if (!val) return "0";
    return `${val}`.replace(",", "");
  };

  const parserNumber = (val: any) => {
    if (!val) return "0";
    return Number.parseFloat(val.replace(",", ".")).toFixed(2);
  };
  return (
    <div
      style={
        current != 1
          ? {
              display: "none",
            }
          : {}
      }
    >
      <Form.Item
        name={["demandeur", "genre"]}
        label={t("inscription.sexe")}
        rules={[{ required: true }]}
      >
        <Select>
          {sexe.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        required
        name={["demandeur", "situationFamiliale"]}
        label={t("inscription.situation_familiale")}
        rules={[{ required: true }]}
      >
        <Select>
          {situation_familiale.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.demandeur?.situationFamiliale !==
          currentValues.demandeur?.situationFamiliale
        }
      >
        {({ getFieldValue }) =>
          situation_familiale
            .filter((s) => s.enfant)
            .map((s) => s.value)
            .includes(getFieldValue(["demandeur", "situationFamiliale"])) ? (
            <Form.Item
              name={["demandeur", "nbrEnfants"]}
              preserve={false}
              label={t("inscription.nbr_enfants")}
              rules={[
                { required: true },
                // { min: 1, max: 20, message: t('messages.nbr_enfants_invalide').toString()}
              ]}
            >
              <Select onChange={handleChangeNbrEnfants} style={{ width: 100 }}>
                {nbChilds.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          
          return (
            prevValues.demandeur?.nbr_enfants_30ans !==
            currentValues.demandeur?.nbr_enfants_30ans
          );
        }}
      >
        {({ getFieldValue }) => {
          return getFieldValue(["demandeur", "nbrEnfants"]) > 0 ? (
            <Form.Item
              name={["demandeur", "nombreEnfantsMoins30Ans"]}
              preserve={false}
              label={t("inscription.nbr_enfants_30ans")}
              rules={[
                { required: true },
              ]}
            >
              <Select style={{ width: 100 }}>
                {nbrEnfantsInf30.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null;
        }}
      </Form.Item>

      <Form.Item
        name={["demandeur", "situationProfessionnelle"]}
        label={t("inscription.situation_professionnelle")}
        rules={[{ required: true }]}
      >
        <Select>
          {situation_professionnelle.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "revenuMensuel"]}
        label={t("inscription.revenu_mensuel")}
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              let floatVal = parseFloat(value);
              if (floatVal >= 0 && floatVal <= 999999) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t("messages.revenu_mensuel_invalide").toString())
              );
            },
          },
        ]}
      >
        <InputNumber<string>
          style={{ width: "40%" }}
          min="0.00"
          stringMode
          keyboard={false}
          controls={false}
          step="0.01"
          formatter={formatterNumber}
          parser={parserNumber}
          addonAfter={t("inscription.dinars")}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.demandeur?.situationProfessionnelle !==
          currentValues.demandeur?.situationProfessionnelle
        }
      >
        {({ getFieldValue }) =>
          [1].includes(
            getFieldValue(["demandeur", "situationProfessionnelle"])
          ) ? (
            <Form.Item
              required
              label={t("inscription.experience_professionnelle")}
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                labelAlign="left"
                name={["demandeur", "anneeExperienceProfessionnelle"]}
                label={t("inscription.annees")}
                style={{ display: "inline-block", margin: "0 8px" }}
                rules={[{ required: true }]}
              >
                <Select style={{ minWidth: 100 }}>
                  {yearsExp.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={["demandeur", "moisExperienceProfessionnelle"]}
                label={t("inscription.mois")}
                style={{ display: "inline-block" }}
                rules={[{ required: true }]}
              >
                <Select style={{ minWidth: 100 }}>
                  {monthsExp.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        name={["demandeur", "nomAr"]}
        label={t("inscription.nom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "nomLt"]}
        rules={[{ required: true }]}
        label={t("inscription.nom_latin")}
        normalize={latinInputNormalize}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomAr"]}
        label={t("inscription.prenom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomLt"]}
        label={t("inscription.prenom_latin")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "wilayaNaissanceId"]}
        label={t("inscription.wilaya_naissance")}
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "communeNaissanceId"]}
        label={t("inscription.commune_naissance")}
        dependencies={["wilayaNaissanceId"]}
        rules={[{ required: true }]}
      >
        <Select>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "numActeNaissance"]}
        label={t("inscription.n_acte_naissance")}
        normalize={nActNormalize}
        rules={[{ required: true }]}
      >
        <CustomInputNumber />
      </Form.Item>

      <Form.Item
        name={["demandeur", "numIdentificationNational"]}
        label={t("inscription.nin")}
        normalize={ninNormalize}
        rules={[
          { required: true },
          { max: 18, message: t("messages.nin_invalide").toString() },
        ]}
      >
        <Input
          addonAfter={

            <img src={InfoCircleOutlined} onClick={handleHelp} width={30} />
           
          }
        />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomPereAr"]}
        label={t("inscription.prenom_pere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomPereLt"]}
        label={t("inscription.prenom_pere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "nomMereAr"]}
        label={t("inscription.nom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "nomMereLt"]}
        label={t("inscription.nom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomMereAr"]}
        label={t("inscription.prenom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomMereLt"]}
        label={t("inscription.prenom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "numTel"]}
        normalize={phoneNormalize}
        label={t("inscription.n_tel")}
        rules={[
          { required: true },
          { max: 10, message: t("messages.tel_invalide").toString() },
          {
            pattern: /^(0)(5|6|7)[0-9]{8}$/,
            message: t("messages.tel_invalide").toString(),
          },
        ]}
      >
        <CustomInputNumber />
      </Form.Item>

      <Form.Item
        name={["demandeur", "email"]}
        label={t("inscription.courriel")}
        rules={[
          { required: true },
          { type: "email", message: t("messages.email_invalide").toString() },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              return (
                executeRecaptcha &&
                executeRecaptcha().then((token) =>
                  checkNewEmail(value, token).then((success) => {
                    if (!success) {
                      return Promise.reject(
                        new Error(t("messages.email_exist").toString())
                      );
                    }
                    return Promise.resolve();
                  })
                )
              );
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Modal
        footer={null}
        centered
        width={800}
        open={showModal}
        onCancel={() => setShowModal(false)}
      >
        <div style={styles.helpImg}></div>
      </Modal>
    </div>
  );
};
export default InformationsStep;
