import FullPageHeader from "./FullPageHeader";
import RuralConstructionSection from "./RuralConstructionSection";
import AboutServiceSection from "./AboutSection";
import TermsOfUseSection from "./TermsOfUseSection";
import RuralConstructionSectionMontant from "./RuralConstructionSectionMontant";
import ContactUs from "./ContacterNous";
import ProcedureSection from "./ProcedureSection";
import RefReferencesSection from "./RegReferences";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);
  return (
    <>
      <FullPageHeader />
      <AboutServiceSection />
      <RuralConstructionSection />
      <TermsOfUseSection />
      <RuralConstructionSectionMontant />
      <RefReferencesSection />
      <ProcedureSection />
      <ContactUs />
    </>
  );
};
export default HomePage;
