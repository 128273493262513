import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader";
import { ResendConfirmationLink } from "../../api/auth";
import { FocusEvent, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import dayjs from "dayjs";
import { getCleASS, nssNormalize } from "../../helpers/validators";

const RensendConfirmationAccount = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({
          demandeur: {
            dateNaissance: res,
          },
        });
      }
    }
    form.validateFields([["demandeur", "dateNaissance"]]);
    form.validateFields([["demandeur", "numSecuriteSociale"]]);
  };

  const nssPattern = () => {
    let nss = form.getFieldValue(["demandeur", "numSecuriteSociale"]) || "";
    let dateNaissance =
      form.getFieldValue(["demandeur", "dateNaissance"]) || "";
    if (`${nss}`.length === 12 && dateNaissance) {
      let year = dateNaissance.format("YY");
      let key = getCleASS(nss);
      let nss2 = `${nss}`.slice(2, 10);
      let pattern = `^${year}${nss2}${key}$`;
      return new RegExp(pattern);
    }
    return new RegExp(`^[0-9]{12}$`);
  };
  const onFinish = ({ demandeur }: any) => {
    setLoading(true);
    if (executeRecaptcha) {
      executeRecaptcha().then((captchaToken) => {
        ResendConfirmationLink({
          nss: demandeur.numSecuriteSociale,
          captchaToken,
        })
          .then((res) => {
            if (res.success) {
              navigate("/login", { replace: true });
              Modal.success({
                title: `${t("formulaire_envoi_confirmation_compte")}`,
                content: `${t("envoi_confirmation_account_success")}`,
                direction: i18n.dir(),
              });
            } else {
              setAlert(true);
              setErrors(res.error);
            }
          })
          .finally(() => setLoading(false));
      });
    } else {
      Modal.error({
        title: t("error"),
        content: (
          <ul>
            <li>{t("captcha_error")}</li>
          </ul>
        ),
      });
    }
  };
  const colSpan = {
    xs: 20,
    md: 16,
    lg: 14,
    xl: 9,
    xxl: 8,
  };

  return (
    <>
      <ContentHeader title={t("formulaire_envoi_confirmation_compte")} />
      <Row justify="center" style={{ flex: 1 }}>
        <Col
          {...colSpan}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {alert && (
            <Alert
              message={t("error")}
              description={
                <ul>
                  {errors.map((err: string,index:number) => {
                    return <li key={index}>{t(err)}</li>;
                  })}
                </ul>
              }
              type="error"
              showIcon
              style={{ margin: "20px 0" }}
              closable
              afterClose={() => setAlert(false)}
            />
          )}
          <Form
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            labelAlign="left"
            form={form}
            validateTrigger="onBlur"
            scrollToFirstError={{
              behavior: "smooth",
            }}
            validateMessages={{
              required: t("messages.champ_obligatoire").toString(),
            }}
          >
            <div
              style={{
                border: "2px solid #ddd",
                padding: 10,
                marginBottom: 20,
              }}
            >
              <p
                style={{
                  width: i18n.language === "ar" ? "90px" : "140px",
                  marginTop: "-24px",
                  marginLeft: "5px",
                  background: "white",
                }}
              >
                {t("inscription.date_naissance")}
              </p>
              <Form.Item
                name={["demandeur", "presumeDemandeur"]}
                label={t("inscription.presume")}
                initialValue={false}
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={false}>{t("inscription.non")}</Radio>
                  <Radio value={true}>{t("inscription.oui")}</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name={["demandeur", "dateNaissance"]}
                label={t("inscription.date_naissance")}
                rules={[
                  { required: true },
                  {
                    validator(_, value) {
                      const presume = form.getFieldValue([
                        "demandeur",
                        "presumeDemandeur",
                      ]);

                      if (!value) {
                        return Promise.resolve();
                      }
                      if (presume) {
                        if (value.month() !== 0 || value.date() !== 1) {
                          return Promise.reject(
                            new Error(t("messages.presume_invalide").toString())
                          );
                        }
                      }
                      if (value.isAfter(dayjs().subtract(16, "year"))) {
                        return Promise.reject(
                          new Error(t("messages.date_cnl_invalide").toString())
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  onBlur={onBlurDateNaissance}
                  style={{ width: "100%" }}
                  placeholder=""
                  defaultPickerValue={dayjs().subtract(16, "year")}
                  disabledDate={(date) =>
                    date.isAfter(dayjs().subtract(16, "year"))
                  }
                />
              </Form.Item>
            </div>
            <Form.Item
              name={["demandeur", "numSecuriteSociale"]}
              label={t("inscription.nss")}
              normalize={nssNormalize}
              dependencies={[["demandeur", "dateNaissance"]]}
              rules={[
                { required: true },
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    let test = nssPattern().test(value);
                    if (!test) {
                      return Promise.reject(
                        new Error(t("messages.nss_invalide").toString())
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button loading={loading} type="primary" htmlType="submit">
                {t("envoyer")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RensendConfirmationAccount;
