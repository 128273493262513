export const JWT_TOKEN_KEY = "fnpos_token";
export const COLOR_PRIMARY = "#376FB8";
export const COLOR_PRIMARY2 = "#046FB8";
export const COLOR_SECONDARY = "#319849";
export const COLOR_PRIMARY_LIGHT = "#dcebf5";
export const BUTTON_STYLE = {
  backgroundColor: COLOR_SECONDARY,
  color: "#fff",
  borderRadius: 50,
  paddingRight: 40,
  paddingLeft: 40,
};
