import React, { useState } from "react";
import DrawerComponenet from "./Drawer";
import { Col, Row } from "antd";
import { COLOR_PRIMARY2 } from "../../../config/constants";
import BurgerMenu from './../../../assets/burger-menu.svg'

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    height: "140px",
    justifyContent: "center",
    zIndex: 5555,
  },
  fnposLogo: {
    width: "100px",
    height: "100px",
    backgroundImage: "url(./logo-fnpos.png)",
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  aps: {
    width: "100px",
    height: "100px",
    backgroundImage: "url(./logo_ministere.png)",
    backgroundSize: "95%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginRight: "10px",
  },
  divider: {
    minWidth: "100%",
    margin: "0",
    backgroundColor: "#ccc",
    height: "3px",
  },
  items: {
    width: "80%",
    textAlign: "center" as "center",
    color: `${COLOR_PRIMARY2}`,
  },

  btn: {
    border: "none",
    color: COLOR_PRIMARY2,
    boxShadow: "none",
    fontWeight: "bold",
    fontSize: 18,
  },
};

export default function MobileHeader() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Row
        id={"navbar"}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "12vh",
          backgroundColor: "white",
        }}
      >
        <Col>
          <div style={styles.fnposLogo}></div>
        </Col>
        <Col>
          {/* <AlignCenterOutlined
            style={{ fontSize: "30px", color: COLOR_PRIMARY2 }}
            onClick={handleClose}
          /> */}
          <img src={BurgerMenu} width={50} alt="fnpos-burger-menu" onClick={handleClose} />
        </Col>
        <Col>
          <div style={styles.aps}></div>
        </Col>
      </Row>
      <Row
        gutter={24}
        style={{
          justifyContent: "center",
          boxShadow: "rgba(50, 50, 93, 0.25) 0px 27px 27px -5px",
          position: "absolute",
          backgroundColor: "white",
          zIndex: 9,
          top: 100,
          display: open ? "flex" : "none",
        }}
      >
        <Col span={24}>
          {open && <DrawerComponenet setOpen={handleClose} />}
        </Col>
      </Row>
    </>
  );
}
