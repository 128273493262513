import { InternalAxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { API } from "../../api/instance";
import { useAuth } from "../../auth/authContext";
import jwtDecode from "jwt-decode";
interface AxiosInterceptorProps {
  children: React.ReactNode;
}

const AxiosInterceptor = ({ children }: AxiosInterceptorProps) => {
  const { token, logout } = useAuth();

  useEffect(() => {
    if (token) {
      const requestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
        let decodedToken: any = jwtDecode(token);
        let currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          logout();
          window.location.replace("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      };
      const interceptor = API.interceptors.request.use(requestInterceptor);
      return () => API.interceptors.request.eject(interceptor);
    }
  }, [token, logout]);

  return <>{children}</>;
};

export default AxiosInterceptor;
