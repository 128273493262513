import { Col, Row, Typography } from "antd";
import { COLOR_PRIMARY2 } from "../../config/constants";


const styles = {
    rowContainer: {
        backgroundColor: COLOR_PRIMARY2,
    },
    title: {
        color: 'white',
        paddingTop: 20,
        paddingBottom: 6,

    }
}
const ContentHeader = ({ title }: { title: string }) => {
    return (
        <Row justify="center" style={styles.rowContainer}>
            <Col>
                <Typography.Title style={styles.title} level={3}>{title}</Typography.Title>
            </Col>
        </Row>
    )
}

export default ContentHeader;