import { Button, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Modal, Radio, Select,  Typography, message } from "antd";
import dayjs from "dayjs";
import { FocusEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICommune, IUpdateDemandeur, IWilaya } from "../../types/types";
import {  arabicInputNormalize, latinInputNormalize, nActNormalize, ninNormalize, phoneNormalize } from "../../helpers/validators";
import InfoCircleOutlined from './../../assets/infocircle.svg';
import {  situation_familiale, situation_professionnelle } from "../../data/data";
import CustomInputNumber from "../../components/CustomInputNumber";
import { UpdateDemandeurInfo } from "../../api/demande";
const yearsExp = Array.from({ length: 46 }, (_, k) => k);
const monthsExp = Array.from({ length: 12 }, (_, k) => k);
const nbChilds = Array.from({ length: 21 }, (_, k) => k);

const styles = {
  helpImg: {
    height: "500px",
    backgroundImage: "url(./help.jpg)",
    backgroundSize: "95%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

export interface IUpdateDemande {
    form: FormInstance;
    wilayas?: IWilaya[];
    isVisible:boolean;
    setIsVisible: (arg0:boolean)=>void;
    onWilayaChange:any;
    communes:ICommune[];
    isUpdated:boolean;
    setIsUpdated: (arg0:boolean)=>void;
    conjointExist:boolean | undefined;
  }
  

const UpdateDemandeur = ({wilayas,isVisible,setIsVisible,form,onWilayaChange,communes,isUpdated,setIsUpdated,conjointExist}:IUpdateDemande) => {
  const { t, i18n } = useTranslation();

  
  const [showModal, setShowModal] = useState(false);
  const [isUpdateDemandeurLoading, setIsUpdateDemandeurLoading] = useState(false);
  const [nbrEnfantsInf30, setNbrEnfantsInf30] = useState<number[]>([0]);



  const handleHelp = () => {
    setShowModal(true);
  };
  function handleChangeNbrEnfants(value: number) {
    setNbrEnfantsInf30(Array.from({ length: value + 1 }, (_, k) => k));
  }
  const formatterNumber = (val: any) => {
    if (!val) return "0";
    return `${val}`.replace(",", "");
  };

  const parserNumber = (val: any) => {
    if (!val) return "0";
    return Number.parseFloat(val.replace(",", ".")).toFixed(2);
  };
  
  const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({
          demandeur: {
            dateNaissance: res,
          },
        });
      }
    }
    form.validateFields([["demandeur", "dateNaissance"]]);
    form.validateFields([["demandeur", "numSecuriteSociale"]]);
  };
  interface IUpdateDemandeurForm {
    demandeur:IUpdateDemandeur
  }
  function updateDemandeur(demandeur:IUpdateDemandeur) {
    setIsUpdateDemandeurLoading(true);
    UpdateDemandeurInfo(demandeur).then(res=>{

      if(res.status===200) { 
        setIsUpdated(!isUpdated);
        Modal.success({
          title: `${t("popup_title_success")}`,
          content: `${t("popup_message_success_demandeur")}`,
          direction: i18n.dir(),
        });
        setIsVisible(false);
      form.resetFields();
      }else {
        let resp = res.response.data;
        if(typeof(resp)!=="string") {              
          Modal.error({
            title: t("popup_title_error"),
            content: (
              <ul>
                {Object.keys(resp.errors).map((e:any, i:number) => (
                  <li key={i}>{t(resp.errors[e])}</li>
                ))}
              </ul>
            ),
            direction: i18n.dir(),
          });
        }else {
          
          Modal.error({
            title: t("popup_title_error"),
            content: (
              <ul>
             
                  <li >{t(resp)}</li>
             
              </ul>
            ),
            direction: i18n.dir(),
          });
          
        }
      }
    }).catch((err:Error)=>{
      Modal.error({
        title: t("popup_title_error"),
        content: (
          <ul>
            <li>{t("popup_message_error_demandeur")}</li>
          </ul>
        ),
      });
    }).finally(()=> {
      setIsUpdateDemandeurLoading(false);
    })
  }
 const onFinish = ({demandeur}: IUpdateDemandeurForm) => {
  if(conjointExist && demandeur.situationFamiliale !== 1) {
    Modal.confirm({
      title: t('confirmation_delete_conjoint_modal_title'),
      content: t('confirmation_delete_conjoint_modal_content'),
      okText:t('btn_confirmer'),
      onOk: ()=> {
    
    updateDemandeur(demandeur);
      }
     });
  }else {
    updateDemandeur(demandeur);

  }
  
 
    
 }

    return (
        <Modal title={  
            <>
            <Typography.Title style={{ margin: 0 }} level={4}>
             {t('modal_edit_demandeur')}
            </Typography.Title>
            <Divider />
          </>} width={700} open={isVisible} onCancel={()=>{
            form.resetFields();
            setIsVisible(false)
            }} onOk={()=>form.submit()} okText={t('modal_btn_save')} cancelButtonProps={{disabled:isUpdateDemandeurLoading}} okButtonProps={{ loading:isUpdateDemandeurLoading}}>
            <Form   
                onFinish={onFinish}
                labelCol={{ span: 9 }}
                labelAlign="left"
                form={form}
                size="large"
                
                validateTrigger="onBlur"
                validateMessages={{
                  required: t("messages.champ_obligatoire").toString(),
                }}>

  
<div style={{ border: "2px solid #ddd", padding: 10, marginBottom: 20 }}>
        <p
          style={{
            width: i18n.language === "ar" ? "90px" : "140px",
            marginTop: "-24px",
            marginLeft: "5px",
            background: "white",
          }}
        >
          {t("inscription.date_naissance")}
        </p>
        <Form.Item name={["demandeur", "id"]} hidden />
          
        
        <Form.Item
          name={["demandeur", "presumeDemandeur"]}
          label={t("inscription.presume")}
          initialValue={false}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={false}>{t("inscription.non")}</Radio>
            <Radio value={true}>{t("inscription.oui")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["demandeur", "dateNaissance"]}
          label={t("inscription.date_naissance")}
          rules={[
            { required: true },
            {
              validator(_, value) {
                const presume = form.getFieldValue([
                  "demandeur",
                  "presumeDemandeur",
                ]);

                if (!value) {
                  return Promise.resolve();
                }
                if (presume) {
                  if (value.month() !== 0 || value.date() !== 1) {
                    return Promise.reject(
                      new Error(t("messages.presume_invalide").toString())
                    );
                  }
                }
                if (value.isAfter(dayjs().subtract(16, "year"))) {
                  return Promise.reject(
                    new Error(t("messages.date_cnl_invalide").toString())
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            onBlur={onBlurDateNaissance}
            style={{ width: "100%" }}
            placeholder=""
            defaultPickerValue={dayjs().subtract(16, "year")}
            disabledDate={(date) => date.isAfter(dayjs().subtract(16, "year"))}
          />
        </Form.Item>
      </div>
      <Form.Item
        required
        name={["demandeur", "situationFamiliale"]}
        label={t("inscription.situation_familiale")}
        rules={[{ required: true }]}
        
      >
        <Select>
          {situation_familiale.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.demandeur?.situationFamiliale !==
          currentValues.demandeur?.situationFamiliale
        }
      >
        {({ getFieldValue }) =>
          situation_familiale
            .filter((s) => s.enfant)
            .map((s) => s.value)
            .includes(getFieldValue(["demandeur", "situationFamiliale"])) ? (
            <Form.Item
              name={["demandeur", "nbrEnfants"]}
              preserve={false}
              label={t("inscription.nbr_enfants")}
              rules={[
                { required: true },
                // { min: 1, max: 20, message: t('messages.nbr_enfants_invalide').toString()}
              ]}
            >
              <Select onChange={handleChangeNbrEnfants} style={{ width: 100 }}>
                {nbChilds.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          
          return (
            prevValues.demandeur?.nbr_enfants_30ans !==
            currentValues.demandeur?.nbr_enfants_30ans
          );
        }}
      >
        {({ getFieldValue }) => {
          return getFieldValue(["demandeur", "nbrEnfants"]) > 0 ? (
            <Form.Item
              name={["demandeur", "nombreEnfantsMoins30Ans"]}
              preserve={false}
              label={t("inscription.nbr_enfants_30ans")}
              rules={[
                { required: true },
              ]}
            >
              <Select style={{ width: 100 }}>
                {nbrEnfantsInf30.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null;
        }}
      </Form.Item>

      <Form.Item
        name={["demandeur", "situationProfessionnelle"]}
        label={t("inscription.situation_professionnelle")}
        rules={[{ required: true }]}
      >
        <Select>
          {situation_professionnelle.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "revenuMensuel"]}
        label={t("inscription.revenu_mensuel")}
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              let floatVal = parseFloat(value);
              if (floatVal >= 0 && floatVal <= 999999) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t("messages.revenu_mensuel_invalide").toString())
              );
            },
          },
        ]}
      >
       
        <InputNumber<string>
          style={{ width: "70%"}}
          min="0.00"
          stringMode
          keyboard={false}
          controls={false}
          step="0.01"
          formatter={formatterNumber}
          parser={parserNumber}
          addonAfter={t("inscription.dinars")}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.demandeur?.situationProfessionnelle !==
          currentValues.demandeur?.situationProfessionnelle
        }
      >
        {({ getFieldValue }) =>
          [1].includes(
            getFieldValue(["demandeur", "situationProfessionnelle"])
          ) ? (
            <Form.Item
              required
              label={t("inscription.experience_professionnelle")}
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                labelAlign="left"
                name={["demandeur", "anneeExperienceProfessionnelle"]}
                label={t("inscription.annees")}
                style={{ display: "inline-block", margin: "0 8px" }}
                rules={[{ required: true }]}
              >
                <Select style={{ minWidth: 100 }}>
                  {yearsExp.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={["demandeur", "moisExperienceProfessionnelle"]}
                label={t("inscription.mois")}
                style={{ display: "inline-block" }}
                rules={[{ required: true }]}
              >
                <Select style={{ minWidth: 100 }}>
                  {monthsExp.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Form.Item
        name={["demandeur", "nomAr"]}
        label={t("inscription.nom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "nomLt"]}
        rules={[{ required: true }]}
        label={t("inscription.nom_latin")}
        normalize={latinInputNormalize}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomAr"]}
        label={t("inscription.prenom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomLt"]}
        label={t("inscription.prenom_latin")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "wilayaNaissanceId"]}
        label={t("inscription.wilaya_naissance")}
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "communeNaissanceId"]}
        label={t("inscription.commune_naissance")}
        dependencies={["wilayaNaissanceId"]}
        rules={[{ required: true }]}
      >
        <Select>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["demandeur", "numActeNaissance"]}
        label={t("inscription.n_acte_naissance")}
        normalize={nActNormalize}
        rules={[{ required: true }]}
      >
        <CustomInputNumber />
      </Form.Item>

      <Form.Item
        name={["demandeur", "numIdentificationNational"]}
        label={t("inscription.nin")}
        normalize={ninNormalize}
        rules={[
          { required: true },
          { max: 18, message: t("messages.nin_invalide").toString() },
        ]}
      >
        <Input
          addonAfter={

            <img src={InfoCircleOutlined} onClick={handleHelp} width={30} />
           
          }
        />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomPereAr"]}
        label={t("inscription.prenom_pere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomPereLt"]}
        label={t("inscription.prenom_pere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "nomMereAr"]}
        label={t("inscription.nom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "nomMereLt"]}
        label={t("inscription.nom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["demandeur", "prenomMereAr"]}
        label={t("inscription.prenom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "prenomMereLt"]}
        label={t("inscription.prenom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["demandeur", "numTel"]}
        normalize={phoneNormalize}
        label={t("inscription.n_tel")}
        rules={[
          { required: true },
          { max: 10, message: t("messages.tel_invalide").toString() },
          {
            pattern: /^(0)(5|6|7)[0-9]{8}$/,
            message: t("messages.tel_invalide").toString(),
          },
        ]}
      >
        <CustomInputNumber />
      </Form.Item>

    
      <Modal
        footer={null}
        centered
        width={800}
        open={showModal}
        onCancel={() => setShowModal(false)}
      >
        <div style={styles.helpImg}></div>
      </Modal>
            </Form>
        </Modal>
    );
}


export default UpdateDemandeur;