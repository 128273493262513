import { DatePicker, Divider, Form, FormInstance, Input, Modal, Select, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { FocusEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITypeDemande, IUpdateDemande, IWilaya } from "../../types/types";
import { arabicAddressNormalize, latinAddressNormalize } from "../../helpers/validators";
import TextArea from "antd/es/input/TextArea";
import { oui_non } from "../../data/data";
import { COLOR_PRIMARY2 } from "../../config/constants";
import { UpdateDemande } from "../../api/demande";
import QuestionCircleTwoTone from "./../../assets/question-circle.svg";

export interface IUpdateDemandeProps {
    form: FormInstance;
    
    isVisible:boolean;
    setIsVisible:(arg0:boolean)=>void;
    isUpdated:boolean;
    setIsUpdated:(arg0:boolean)=>void;
    typesDemande:ITypeDemande[]
    

  }
  

const UpdateDemnde = ({isVisible,form,setIsVisible,typesDemande,isUpdated, setIsUpdated}:IUpdateDemandeProps) => {
    const {i18n,t} = useTranslation();
    const [isUpdateDemandeLoading, setIsUpdateDemandeLoading] = useState(false);

   
    const onBlurDateCnl = (e: FocusEvent<HTMLInputElement, Element>) => {
        let date = e.target.value;
        if (date) {
          let date1 = dayjs(date, "YYYY-MM-DD");
          let date2 = dayjs(date, "DD-MM-YYYY");
          let date3 = dayjs(date, "DD/MM/YYYY");
          let date4 = dayjs(date, "YYYY/MM/DD");
          let res = date1.isValid()
            ? date1
            : date2.isValid()
            ? date2
            : date3.isValid()
            ? date3
            : date4.isValid()
            ? date4
            : null;
          if (res) {
            form.setFieldsValue({ dateDecisionCnl: res });
          }
        }
        form.validateFields(["dateDecisionCnl"]);
      };
    
      const onBlurDateDelivance = (e: FocusEvent<HTMLInputElement, Element>) => {
        let date = e.target.value;
        if (date) {
          let date1 = dayjs(date, "YYYY-MM-DD");
          let date2 = dayjs(date, "DD-MM-YYYY");
          let date3 = dayjs(date, "DD/MM/YYYY");
          let date4 = dayjs(date, "YYYY/MM/DD");
          let res = date1.isValid()
            ? date1
            : date2.isValid()
            ? date2
            : date3.isValid()
            ? date3
            : date4.isValid()
            ? date4
            : null;
          if (res) {
            form.setFieldsValue({
              dateDelivancePermisConstruction: res,
            });
          }
        }
        form.validateFields(["dateDelivancePermisConstruction"]);
      };


 const onFinish = (values:IUpdateDemande) => {
  setIsUpdateDemandeLoading(true);
    UpdateDemande(values).then(res=>{
      if(res.status===200) {
        setIsUpdated(!isUpdated);
        Modal.success({
          title: `${t("popup_title_success")}`,
          content: `${t("popup_message_success_demande")}`,
          direction: i18n.dir(),
        });
        setIsVisible(false);
      form.resetFields();
      }else {
        let resp = res.response.data;
        if(typeof(resp)!=="string") {              
          Modal.error({
            title: t("popup_title_error"),
            content: (
              <ul>
                {Object.keys(resp.errors).map((e:any, i:number) => (
                  <li key={i}>{t(resp.errors[e])}</li>
                ))}
              </ul>
            ),
            direction: i18n.dir(),
          });
        }else {
          
          Modal.error({
            title: t("popup_title_error"),
            content: (
              <ul>
             
                  <li >{t(resp)}</li>
             
              </ul>
            ),
            direction: i18n.dir(),
          });
          
        }
      }
      
    }).catch(err=>{
      Modal.error({
        title: t("popup_title_error"),
        content: (
          <ul>
            <li>{t("popup_message_error_demande")}</li>
          </ul>
        ),
      });
    }).finally(()=> {
      setIsUpdateDemandeLoading(false);
    });
 }

    return (
        <Modal title={  
            <>
            <Typography.Title style={{ margin: 0 }} level={4}>
            {t('modal_edit_demande')}
            </Typography.Title>
            <Divider />
          </>} width={700} open={isVisible} onCancel={()=>{
            setIsVisible(false);
            form.resetFields();
            }} onOk={()=>form.submit()}
            okText={t('modal_btn_save')}
             cancelButtonProps={{disabled:isUpdateDemandeLoading}}
              okButtonProps={{ loading:isUpdateDemandeLoading}}>
            <Form   
                onFinish={onFinish}
                labelCol={{ span: 9 }}
                labelAlign="left"
                form={form}
                size="large"
                
                validateTrigger="onBlur"
                validateMessages={{
                  required: t("messages.champ_obligatoire").toString(),
                }}>

            {/* <Form.Item
        name="typeDemandeId"
        label={t("inscription.demande_aide_pour")}
        rules={[{ required: true }]}
      >
        <Select>
          {typesDemande.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {i18n.language == "ar" ? item.typeAr : item.typeLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
      <Form.Item name="id" hidden />
      <Form.Item name="typeDemandeId" hidden />
      
      <Form.Item
        name="dateDecisionCnl"
        label={t("inscription.date_decision_cnl")}
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.isBefore(dayjs(typesDemande[0].dateCNLMin))) {
                return Promise.reject(
                  new Error(
                    t(`messages.date_cnl_invalide`).toString() +
                      `${dayjs(typesDemande[0].dateCNLMin).format(
                        "DD/MM/YYYY"
                      )}`
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          defaultPickerValue={dayjs(typesDemande[0]?.dateCNLMin)}
          style={{ width: "100%" }}
          placeholder=""
          onBlur={onBlurDateCnl}
          disabledDate={(date) => {
            return date.isBefore(dayjs(typesDemande[0]?.dateCNLMin));
          }}
        />
      </Form.Item>
      <Form.Item
        name="numDecisionCnl"
        label={t("inscription.numero_decision_cnl")}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        name="wilayaId"
        label={t("inscription.wilaya_projet")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
      <Form.Item
        normalize={latinAddressNormalize}
        labelCol={{ lg: i18n.language == "ar" ? 9 : 11 }}
        rules={[{ required: true }]}
        name={"adresseConstructionFr"}
        label={t("inscription.adress_construction_lt")}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={"adresseConstructionAr"}
        labelCol={{ lg: i18n.language == "ar" ? 9 : 11 }}
        label={t("inscription.adress_construction_ar")}
        normalize={arabicAddressNormalize}
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name="deuxiemeTrancheVersee"
        label={t("inscription.deuxieme_tranche_versee")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select  
        className="select-travaux-achevés"
          suffixIcon={
            <Tooltip
              title={t("inscription.tooltip_deuxieme_tranche_versee")}
              color={COLOR_PRIMARY2}
            >
              <img
                width={25}
                height={25}
                src={QuestionCircleTwoTone}
                alt="fnpos-question"
              />
            </Tooltip>
          }>
          {oui_non.map((state) => (
            <Select.Option key={state.value} value={state.value}>
              {t(state.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        labelCol={{ lg: i18n.language === "ar" ? 9 : 11 }}
        name="projetFinalise"
        label={t("inscription.projet_finalise")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select
          className="select-travaux-achevés"
          suffixIcon={
            <Tooltip
              title={t("inscription.tooltip_projet")}
              color={COLOR_PRIMARY2}
            >
              <img
                width={25}
                height={25}
                src={QuestionCircleTwoTone}
                alt="fnpos-question"
              />
            </Tooltip>
          }
        >
          {oui_non.map((state) => (
            <Select.Option key={state.value} value={state.value}>
              {t(state.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        labelCol={{ lg: i18n.language === "ar" ? 9 : 14 }}
        name="dateDelivancePermisConstruction"
        label={t("inscription.date_delivance_permis_construction")}
        validateTrigger="onBlur"
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.isAfter(dayjs())) {
                return Promise.reject(
                  new Error(t("messages.date_invalide").toString())
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          placeholder=""
          onBlur={onBlurDateDelivance}
          disabledDate={(date) => date.isAfter(dayjs())}
        />
      </Form.Item>
            </Form>
        </Modal>
    );
}


export default UpdateDemnde;